import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProcessosIframe from "~/components/ProcessosIframe";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import { setProcessFormMenuItemClicked } from "~/containers/Layout/actions";
import NativeProcessesWrapper from "./NativeProcessesWrapper";
import oauth from "~/utils/oauth";
import {
  getOrganizations,
  clearOrganizations,
} from "~/store/ducks/organizations/actionTypes";
import AlertComponentWrapper from "./AlertComponentWrapper";

const devLogConsole = require("~/utils/devLog");
const ProcessosFormHandler = (props) => {
  const { app, className, general } = props;
  const dispatch = useDispatch();
  const { currentFormUrl, currentFormMenuItemClicked } = useSelector(
    (state) => state.globalReducer
  );
  const { formdata } = useSelector((state) => state.processesReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { organizations } = useSelector((state) => state.organizationsReducer);

  useEffect(() => {
    dispatch(getOrganizations());
    return () => {
      dispatch(clearOrganizations());
    };
  }, [dispatch]);

  // const removejsonFromFormdata = (data) => {
  //   if (typeof data === "string" && data.includes(`{"form" :`)) {
  //     let arr = formdata.split(`{"form" :`);
  //     let re = /\"\" style=\"/g;
  //     let transformedString = (`{"form" :` + arr[1]).replace(re, '" style=');
  //     transformedString = transformedString
  //       .replace(/\\/g, "\\\\")
  //       .replace(/(\r\n|\n|\r|\t)/gm, "");
  //     return JSON.parse(transformedString);
  //   } else {
  //     if (typeof data === "object") return data;
  //   }
  // };

  function removejsonFromFormdata(formdata) {
    if (
      typeof formdata === "object" &&
      formdata !== null &&
      !formdata.responseMessage?.includes('<meta formType="HTML">')
    ) {
      return true;
    } else if (typeof formdata === "string" && formdata.includes(`{"form" :`)) {
      return true;
    }
    return false;
  }

  // const renderProcesses = () => {
  //   if (menu && currentFormUrl !== "") {
  //     const menuItem = menu.find((item) => item.route === `${app}/processos`);
  //     if (menuItem) {
  //       const extraConfiguration_ = JSON.parse(
  //         menuItem.extraConfiguration || false
  //       );
  //       const nativeFlows_ = extraConfiguration_.nativeFlows;
  //       if (nativeFlows_ && currentFormUrl) {
  //         if (
  //           nativeFlows_.some((flow) =>
  //             currentFormUrl.includes(`flowid=${flow}`)
  //           )
  //         ) {
  //           return <NativeProcessesWrapper className={className} />;
  //         } else return <ProcessosIframe className={className} />;
  //       } else return <ProcessosIframe className={className} />;
  //     }
  //   }
  // };

  const renderProcesses = () => {
    const data = JSON.parse(localStorage.getItem("dataToStore")) || {};
    const openNewTabMenuLocal =
      JSON.parse(localStorage.getItem("openNewTabMenu")) || false;
    const dataToStoreTemp = {
      shouldRenderProcesses: true,
      currentFormUrl: currentFormUrl,
      className: className,
      formdata: formdata,
      windowLocation: window.location.href,
    };
    devLogConsole(dataToStoreTemp, "dataToStoreTemp");
    const shouldOpenInNewTab =
      organizations[0]?.openNewTab || openNewTabMenuLocal === true;
    const isSameLocation = data?.windowLocation === window.location.href;
    const hasFormData =
      dataToStoreTemp.currentFormUrl !== "" &&
      dataToStoreTemp.formdata !== null;

    if (hasFormData) {
      const isJson = removejsonFromFormdata(dataToStoreTemp.formdata);
      if (shouldOpenInNewTab) {
        localStorage.setItem("dataToStore", JSON.stringify(dataToStoreTemp));
        window.open(dataToStoreTemp.windowLocation, "_blank");
      } else {
        if (
          typeof dataToStoreTemp.formdata === "boolean" ||
          (typeof dataToStoreTemp.formdata === "string" &&
            (dataToStoreTemp.formdata.includes("session") ||
              dataToStoreTemp.formdata.responseMessage?.includes(
                "<title>Reauth"
              ) ||
              dataToStoreTemp.formdata.includes(
                "Ocorreu um erro"
              )))
        ) {
          return <AlertComponentWrapper />;
        }
        const Component = isJson ? NativeProcessesWrapper : ProcessosIframe;
        return (
          <Component
            className={className}
            formFromProcess={isJson ? dataToStoreTemp.formdata : undefined}
            urlFormData={!isJson ? dataToStoreTemp.currentFormUrl : undefined}
          />
        );
      }
    }
    if (
      (data !== null && !shouldOpenInNewTab && isSameLocation) ||
      (data !== null && openNewTabMenuLocal && isSameLocation)
    ) {
      if (data.formdata !== null) {
        const isJson = removejsonFromFormdata(data.formdata);
        if (
          typeof data.formdata === "boolean" ||
          (typeof data.formdata === "string" &&
            (data.formdata.includes("session") ||
              data.formdata.responseMessage?.includes("<title>Reauth") ||
              data.formdata.includes("Ocorreu um erro")))
        ) {
          return <AlertComponentWrapper />;
        }
        const Component = isJson ? NativeProcessesWrapper : ProcessosIframe;
        return (
          <Component
            className={className}
            urlFormData={!isJson ? data.currentFormUrl : undefined}
          />
        );
      }
    }
  };

  useEffect(() => {
    const dataToStore = JSON.parse(localStorage.getItem("dataToStore"));
    let url = "";
    devLogConsole(
      currentFormUrl,
      "currentFormUrl--------AQUI*****************-"
    );
    if (menu && currentFormUrl !== "" && currentFormMenuItemClicked === true) {
      const menuItem = menu.find((item) => item.route === `${app}/processos`);
      if (menuItem && currentFormUrl) {
        url = currentFormUrl;
        dispatch(setProcessFormMenuItemClicked(false));
      }
      if (general) {
        url = "inicio_flow.jsp?flowid=" + currentFormUrl;
      }
    } else if (currentFormUrl === "" && dataToStore != null) {
      url = dataToStore.currentFormUrl;
    }

    if (url) {
      const payload = {
        url: url + "&Authorization=" + oauth.getAccessToken(),
      };
      const isNewForm = true;
      dispatch(getIflowFormdata(payload, isNewForm));
    }

    return () => {
      localStorage.removeItem("iflowAccordion");
    };
  }, [currentFormUrl, currentFormMenuItemClicked]);

  return <>{renderProcesses()}</>;
};
export default ProcessosFormHandler;
