import { subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomBreadcrumb from "~/components/CustomBreadcrumb";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import FlowModal from "../FlowModal";
import Categories from "./Categories";
import PreviewModal from "./PreviewModal";
import TaskFilters from "./TaskFilters";
import TaskTable from "./TaskTable";
import FilterCategoryToggle from "./FilterCategoryToggle";
import { findFolders } from "~/store/ducks/taskFolders/actionTypes";
import NativeProcessesWrapper from "~/components/ProcessosFormHandler/NativeProcessesWrapper";
import { getIflowFormdata } from "~/store/ducks/processes/actionTypes";
import { findIflowTasksByFilters } from "~/store/ducks/tasks/actionTypes";
import { findLegacyUsers, setLegacyUsers } from "~/store/ducks/udw/actionTypes";
import { setSelectedTosignTableFileComponent } from "~/store/ducks/processesSignature/actionTypes";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import oauth from "~/utils/oauth";
import "~/assets/css/icons.css";
import { setFlowIdMyTasksRequest } from "~/store/ducks/portalrh/actionTypes";

const Tasks = ({ app, className, styleSheet, translations, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { legacyUsers } = useSelector((state) => state.udwReducer);
  const { isLoadingTasks, numbercountTotal, numbercount } = useSelector(
    (state) => state.tasksReducer
  );
  const { folders, successUpdate } = useSelector(
    (state) => state.taskFoldersReducer
  );

  const { successUpdateAnnotation } = useSelector(
    (state) => state.processAnnotationsReducer
  );
  const { isLoadingUpdateReadFlag } = useSelector(
    (state) => state.tasksReducer
  );
  const { isLoadingFormdata, history, formdata } = useSelector(
    (state) => state.processesReducer
  );
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const language = userSettings.language ? userSettings.language : "en";
  const [selectedFolder, setSelectedFolder] = useState(undefined);
  const { flowidMyTasksRequest } = useSelector(
    (state) => state.portalrhReducer
  );

  /*Filters Start*/
  const [flowId, setFlowId] = useState("");
  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");
  const [nProcess, setNProcess] = useState("");
  const [description, setDescription] = useState("");
  const [previousUser, setPreviousUser] = useState("");
  const [flowFiltersValues, setFlowFiltersValues] = useState(undefined);
  const [headerName, setHeaderName] = useState("");
  const [modalClassName, setModalClassName] = useState("modal-tarefa-97w");
  const [iframeClassName, setIframeClassName] = useState("iframe-tarefa-80w");
  const [iconClassName, setIconClassName] = useState("icon-maximize mr-4");
  const [isMaximized, setIsMaximised] = useState(false);
  /*Filters End*/

  const [showCategories, setShowCategories] = useState(true);

  /*Active keys for Filter and Categories accordion*/
  const [activeKeys, setActiveKeys] = useState({
    filter: "0",
    categories: null,
  });
  const [filterToggle, setFilterToggle] = useState(true); //default show all filters
  const [activeFilters, setActiveFilters] = useState(false);

  useEffect(() => {
    if (flowidMyTasksRequest !== null) {
      handleFlowId(flowidMyTasksRequest);
      setActiveFilters(true);
      dispatch(setFlowIdMyTasksRequest(null));
    }
  }, [flowidMyTasksRequest]);

  const handleFlowId = (flowId) => {
    setFlowId(flowId);
  };
  const handleEndAt = (endAt_) => {
    setEndAt(endAt_);
  };
  const handleStartAt = (startAt_) => {
    setStartAt(startAt_);
  };
  const handleNProcess = (nProcess_) => {
    setNProcess(nProcess_);
  };
  const handleDescription = (description_) => {
    setDescription(description_);
  };
  const handlePreviousUser = (previousUser_) => {
    setPreviousUser(previousUser_);
  };
  const handleFlowFiltersValues = (values) => {
    setFlowFiltersValues(values);
  };

  const handleClose = () => {
    if (isMaximized) {
      setModalClassName("modal-tarefa-100w");
      setIconClassName("icon-restore mr-4");
    } else {
      setModalClassName("modal-tarefa-97w");
      setIconClassName("icon-maximize mr-4");
    }
    setIframeClassName("iframe-tarefa-80w");
    handleCloseFlowModal();
  };

  const TaskIframe = ({ src, iframeClassName }) => {
    return (
      <iframe
        id="process1"
        title="process-flow"
        src={src}
        className={iframeClassName}
      />
    );
  };

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    );
    return userBPM ? userBPM.username : null;
  };

  const userBPM = user.legacyUsers ? getUsername(user.legacyUsers) : null;

  const [defaultFlowId, setDefaultFlowId] = useState(undefined);
  const [period, setPeriod] = useState("");
  const [hiddenDefaults, setHiddenDeafults] = useState(undefined);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [selectedPreview, setSelectedPreview] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  /*Get default values for flowId and period*/
  useEffect(() => {
    const handleDates = (period) => {
      if (period === "all") {
        handleEndAt("");
        handleStartAt("");
      } else {
        const numOfDays = Number.parseInt(period);
        if (numOfDays) {
          handleEndAt("");
          handleStartAt("");
        }
      }
    };
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/tarefas` || item.route === `${app}/tasks`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const defaultFlowId_ = JSON.parse(
          (extraConfiguration_ && extraConfiguration_.defaultFlowId) || false
        );
        const period_ = extraConfiguration_.period;
        if (defaultFlowId_) {
          handleFlowId(defaultFlowId_);
          setDefaultFlowId(defaultFlowId_);
          handleFlowFiltersValues(undefined); /*Clean flow filters*/
        }
        if (period_) {
          handleDates(period_);
          setPeriod(period_);
        }
        let hiddenDefaults_;
        if (flowId && extraConfiguration_[flowId]) {
          hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default filters for a specific flow
        } else {
          hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default filters for all flows
        }
        setHiddenDeafults(hiddenDefaults_ ? hiddenDefaults_ : undefined);
        /*find categories settings*/
        const showCategories_ =
          extraConfiguration_.categories === "false" ? false : true;
        setShowCategories(showCategories_);

        /*find if filter is closed by config*/
        let filterToggle_;
        filterToggle_ = extraConfiguration_.filterToggle;
        setFilterToggle(filterToggle_);
      }
    }
  }, [menu, flowId]);

  useEffect(() => {
    if (hiddenDefaults) {
      if (hiddenDefaults.some((col) => col === "from")) {
        handleEndAt("");
        handleStartAt("");
      }
    }
  }, [hiddenDefaults]);

  useEffect(() => {
    setActiveKeys((activeKeys) => {
      activeKeys.filter = filterToggle === "false" ? null : "0";
      return activeKeys;
    });
  }, [filterToggle]);

  const findTasks = (legacyUsername) => {
    if (userBPM) {
      let metadataname = [];
      let metadatavalue = [];
      if (flowFiltersValues)
        Object.keys(flowFiltersValues).map((key) => {
          if (flowFiltersValues[key] !== "") {
            metadataname.push(key);
            metadatavalue.push(flowFiltersValues[key]);
          }
        });
      let payload = {};
      const payloadArray = [
        {
          name: "username",
          value: userBPM,
        },
        {
          name: "application",
          value: app,
        },
        {
          name: "pnumber",
          value: nProcess,
        },
        {
          name: "description",
          value: description,
        },
        {
          name: "flowid",
          value: flowId,
        },
        {
          name: "from",
          value: startAt && startAt.getTime(),
        },
        {
          name: "to",
          value: endAt && endAt.getTime(),
        },
        {
          name: "previoususer",
          value: legacyUsername ? legacyUsername : "",
        },
      ];

      if (flowId || startAt || endAt || nProcess) {
        setActiveFilters(true);
      } else {
        setActiveFilters(false);
      }
      const payload_ = payloadArray.filter(
        (item) =>
          item.value !== "" && item.value !== undefined && item.value !== null
      );
      payload_.forEach((item) => {
        payload[item.name] = item.value;
      });
      if (metadatavalue.length !== 0)
        payload = { ...payload, metadataname, metadatavalue };
      dispatch(findIflowTasksByFilters(payload));
    }
  };

  // useEffect(()=>{
  //   let url = window.location.href;
  //     if (url.includes("opentask=true")) {
  //       console.log("NTREA------- URL")
  //       const match = url.match(/[?&](flowid|pid|supid)=([^&]+)/g);
  //       if (match) {
  //         const params = {};
  //         match.forEach((param) => {
  //           const [key, value] = param.substring(1).split("=");
  //           params[key] = value;
  //         });
  //         const flowid = params.flowid;
  //         const pid = params.pid;
  //         const supid = params.subpid;
  //         alert(supid, flowid, pid)
  //         url: "Forward/forward.jsp?flowid="+flowid+"&pid="+pid+"&subpid="+supid + "&Authorization=" + oauth.getAccessToken(),
  //         const payload = {
  //           url: "Forward/forward.jsp?flowid="+flowid+"&pid="+pid+"&subpid="+supid + "&Authorization=" + oauth.getAccessToken(),
  //         };
  //         const isNewForm = true
  //         dispatch(getIflowFormdata(payload, isNewForm));

  //         const index = url.indexOf('?');
  //         url = url.substring(0, index);
  //         window.location.replace(url)
  //         return       <FlowModal
  //         showModal={showFlowModal}
  //         closeModal={handleCloseFlowModal}
  //         processNumber={selectedTask && selectedTask.pnumber}
  //         process={selectedTask}
  //         src={src}
  //         app={app}
  //       />
  //       }
  //       if (formdata.includes(`{"form" :`)) {

  //         let arr = formdata.split(`{"form" :`);
  //         let re = /\"\" style=\"/g;
  //         let transformedString = (`{"form" :` + arr[1]).replace(re, '" style=');
  //         // Remova todas as quebras de linha de uma string
  //         transformedString = transformedString
  //           .replace(/\\/g, "\\\\")
  //           .replace(/(\r\n|\n|\r|\t)/gm, "");
  //         const isJson = JSON.parse(transformedString);
  //         return <NativeProcessesWrapper
  //           isModal={true}
  //           handleHeaderName={setHeaderName}
  //           handleClose={handleClose}
  //           formFromProcess={isJson}
  //         />;
  //       } else {
  //         return <TaskIframe src={src} iframeClassName={iframeClassName} />;
  //       }
  //     }
  // },[window.location.href])

  /*Default Search*/
  useEffect(() => {
    findTasks();
  }, [flowId, hiddenDefaults, period, user]);

  // useEffect(() => {
  //   handleReset()
  // }, []);
  /*Find Tasks with selected Filter Utilizador(variable from)*/
  useEffect(() => {
    if (legacyUsers && legacyUsers.length !== 0) {
      let legacyUsername;
      legacyUsers.forEach((user) => {
        if (user.legacyApplication.label === BPM_MIDDLEWARE.context)
          legacyUsername = user.username;
      });
      findTasks(legacyUsername ? legacyUsername : "");
      dispatch(setLegacyUsers([])); /*Clean store*/
    }
  }, [dispatch, legacyUsers]);

  /*Find Folders first time*/
  useEffect(() => {
    if (userBPM && app) {
      dispatch(findFolders(userBPM, app));
    }
  }, [dispatch, userBPM, app]);

  /*Find Folders after Folder Persist*/
  useEffect(() => {
    if (successUpdate && app) {
      dispatch(findFolders(userBPM, app));
      handleSearch();
    }
    if (successUpdateAnnotation) {
      handleSearch();
    }
    if (isLoadingUpdateReadFlag) {
      handleSearch();
    }
  }, [successUpdate, app, successUpdateAnnotation, isLoadingUpdateReadFlag]);

  const handleSearch = () => {
    if (previousUser !== "" && previousUser !== undefined) {
      dispatch(findLegacyUsers(previousUser));
    } else {
      findTasks();
    }
  };

  const handleReset = () => {
    setActiveFilters(false);
    let startAt_ = subDays(new Date(), 7).getTime();
    let endAt_ = new Date().getTime();
    if (period) {
      if (period === "all") {
        startAt_ = "";
        endAt_ = "";
      } else {
        const numOfDays = Number.parseInt(period);
        if (numOfDays) {
          startAt_ = subDays(new Date(), numOfDays).getTime();
          endAt_ = new Date().getTime();
        }
      }
    }
    if (hiddenDefaults) {
      if (hiddenDefaults.some((col) => col === "from")) {
        startAt_ = "";
        endAt_ = "";
      }
    }
    const payloadArray = [
      {
        name: "username",
        value: userBPM,
      },
      {
        name: "application",
        value: app,
      },
      {
        name: "flowid",
        value: defaultFlowId,
      },
      {
        name: "from",
        value: startAt_,
      },
      {
        name: "to",
        value: endAt_,
      },
    ];
    let payload = {};
    const payload_ = payloadArray.filter(
      (item) => item.value !== "" && item.value !== undefined
    );
    payload_.forEach((item) => {
      payload[item.name] = item.value;
    });
    dispatch(findIflowTasksByFilters(payload));

    handleFlowId(defaultFlowId ? defaultFlowId : "");
    handleNProcess("");
    handleDescription("");
    handleFlowFiltersValues(undefined); /*Clean flow filters*/
    handlePreviousUser("");

    const handleDates = (period) => {
      if (period === "all") {
        handleEndAt("");
        handleStartAt("");
      } else {
        const numOfDays = Number.parseInt(period);
        if (numOfDays) {
          handleEndAt("");
          handleStartAt("");
        }
      }
    };
    if (period) {
      handleDates(period);
    } else {
      handleEndAt("");
      handleStartAt("");
    }
  };

  const src = selectedTask
    ? "iFlow/" + selectedTask.url + "&Authorization=" + oauth.getAccessToken()
    : "";

  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
    dispatch(setSelectedTosignTableFileComponent([]));
    handleSearch();
  };
  const handleOpenFlowModal = (row) => {
    setShowFlowModal(true);
    setSelectedTask(row);
  };
  const handleOpenPreviewModal = (row) => {
    setShowPreviewModal(true);
    setSelectedPreview(row);
  };
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const handleActiveKey = (name) => {
    let activeKeys_ = { ...activeKeys };
    activeKeys_[name] = activeKeys[name] === null ? "0" : null;
    setActiveKeys(activeKeys_);
  };

  const isMobile = window.innerWidth < 992 ? true : false;
  return (
    <>
      {isMobile && <CustomBreadcrumb />}

      <div
        className={
          className ? `${className} scrollBar-visible` : "main-card-v2"
        }
        style={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        <Card bsPrefix="card-flat" style={{ overflowY: "visible" }}>
          <div className="custom-title">{t("taskPage.general.tasks")}</div>
          <Card.Body>
            <FilterCategoryToggle
              handleActiveKey={handleActiveKey}
              activeKeys={activeKeys}
              filterActive={activeFilters}
            />
            <TaskFilters
              app={app}
              flowId={flowId}
              startAt={startAt}
              endAt={endAt}
              nProcess={nProcess}
              previousUser={previousUser}
              flowFiltersValues={flowFiltersValues}
              hiddenDefaults={hiddenDefaults}
              handleFlowFiltersValues={handleFlowFiltersValues}
              handleFlowId={handleFlowId}
              handleStartAt={handleStartAt}
              handleEndAt={handleEndAt}
              handleNProcess={handleNProcess}
              handleDescription={handleDescription}
              handlePreviousUser={handlePreviousUser}
              handleSearch={handleSearch}
              handleReset={handleReset}
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
            />
            {showCategories && (
              <Categories
                app={app}
                folders={folders}
                userBPM={userBPM}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                activeKeys={activeKeys}
                setActiveKeys={setActiveKeys}
              />
            )}

            <>
              <TaskTable
                app={app}
                flowId={flowId}
                handleOpenPreviewModal={handleOpenPreviewModal}
                handleOpenFlowModal={handleOpenFlowModal}
                folders={folders}
                selectedFolder={selectedFolder}
                userBPM={userBPM}
                showCategories={showCategories}
                styleSheet={styleSheet}
                handleSearch={handleSearch}
                translations={translations}
                language={language}
              />
            </>
          </Card.Body>
        </Card>
      </div>
      <FlowModal
        showModal={showFlowModal}
        closeModal={handleCloseFlowModal}
        processNumber={selectedTask && selectedTask.pnumber}
        process={selectedTask}
        src={src}
        app={app}
      />
      <PreviewModal
        showModal={showPreviewModal}
        closeModal={handleClosePreviewModal}
        task={selectedPreview}
        app={app}
      />
      <Messages />
      <Spinner spinning={isLoadingTasks} wrapper />
    </>
  );
};
export default withNamespaces()(Tasks);
