import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import RhTableList from "./RhTableList";
import { DateTime } from "luxon";
import { withNamespaces } from "react-i18next";
import Dropdown from "~/components/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  getTraining,
  getTrainingDropdown,
  updateTraining,
  createTraining,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";

const RhTrainingSheet = ({ rowCollaborator, t }) => {
  const [rowClicked, setRowClicked] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [oldStartDate, setOldStartDate] = useState("");
  const [numberOfHours, setNumberOfHours] = useState("");
  const [comments, setComments] = useState("");

  const [trainingActList, setTrainingActList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [trainingEntList, setTrainingEntList] = useState([]);
  const [reasonTrainingList, setReasonTrainingList] = useState([]);
  const [perRefFormacaoList, setPerRefFormacaoList] = useState([]);
  const [iniciativeList, setIniciativeList] = useState([]);
  const [certTypeList, setCertTypeList] = useState([]);
  // const [stateList, setStateList] = useState([]);

  const [trainingActSelected, setTrainingActSelected] = useState("");
  const [domainSelected, setDomainSelected] = useState("");
  const [trainingEntSelected, setTrainingEntSelected] = useState("");
  const [reasonTrainingSelected, setReasonTrainingSelected] = useState("");
  const [perRefFormacaoSelected, setPerRefFormacaoSelected] = useState("");
  const [iniciativeSelected, setIniciativeSelected] = useState("");
  const [certTypeSelected, setCertTypeSelected] = useState("");
  // const [stateSelected, setStateSelected] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const [checkOnJob, setCheckOnJob] = useState("");

  const [trainingSheetPage, setTrainingSheetPage] = useState(false);

  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);

  const [empCode, setEmpCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);

  const [trainingActSelectedError, setTrainingActSelectedError] =
    useState(false);
  const [domainSelectedError, setDomainSelectedError] = useState(false);
  const [trainingEntSelectedError, setTrainingEntSelectedError] =
    useState(false);
  const [reasonTrainingSelectedError, setReasonTrainingSelectedError] =
    useState(false);
  const [perRefFormacaoSelectedError, setPerRefFormacaoSelectedError] =
    useState(false);
  const [iniciativeSelectedError, setIniciativeSelectedError] = useState(false);
  const [certTypeSelectedError, setCertTypeSelectedError] = useState(false);
  // const [stateSelectedError, setStateSelectedError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);

  const dispatch = useDispatch();

  const { trainingData, trainingDropdown, isLoadingUpdateTraining } =
    useSelector((state) => state.personalReducer);

  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getTrainingDropdown());
      dispatch(
        getTraining(rowCollaborator.funcionarioNum, rowCollaborator.codEntidade)
      );
      setTrainingSheetPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateTraining]);

  const handleTrainingAct = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setTrainingActSelectedError(false);
    setTrainingActSelected(e);
  };

  const handleDomain = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setDomainSelectedError(false);
    setDomainSelected(e);
  };

  const handleTrainingEnt = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setTrainingEntSelectedError(false);
    setTrainingEntSelected(e);
  };

  const handleReasonTraining = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setReasonTrainingSelectedError(false);
    setReasonTrainingSelected(e);
  };

  const handlePerRefFormacao = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setPerRefFormacaoSelectedError(false);
    setPerRefFormacaoSelected(e);
  };

  const handleIniciative = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setIniciativeSelectedError(false);
    setIniciativeSelected(e);
  };

  const hanldeCertType = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setCertTypeSelectedError(false);
    setCertTypeSelected(e);
  };

  // const hanldeState = (e) => {
  //   if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
  //     setStateSelectedError(false);
  //   setStateSelected(e);
  // }

  const handleStartDate = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setStartDateError(false);
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setEndDateError(false);
    setEndDate(e);
  };

  const handleNumbHours = (e) => {
    setNumberOfHours(e.target.value);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleCheck = (e) => {
    e.persist();
    setCheckOnJob(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      trainingActSelected === null ||
      trainingActSelected === undefined ||
      trainingActSelected === "" ||
      trainingActSelected === 0 ||
      trainingActSelected === "0"
    )
      setTrainingActSelectedError(true);
    if (
      domainSelected === null ||
      domainSelected === undefined ||
      domainSelected === "" ||
      domainSelected === 0 ||
      domainSelected === "0"
    )
      setDomainSelectedError(true);
    if (
      trainingEntSelected === null ||
      trainingEntSelected === undefined ||
      trainingEntSelected === "" ||
      trainingEntSelected === 0 ||
      trainingEntSelected === "0"
    )
      setTrainingEntSelectedError(true);
    if (
      reasonTrainingSelected === null ||
      reasonTrainingSelected === undefined ||
      reasonTrainingSelected === "" ||
      reasonTrainingSelected === 0 ||
      reasonTrainingSelected === "0"
    )
      setReasonTrainingSelectedError(true);
    if (
      perRefFormacaoSelected === null ||
      perRefFormacaoSelected === undefined ||
      perRefFormacaoSelected === "" ||
      perRefFormacaoSelected === 0 ||
      perRefFormacaoSelected === "0"
    )
      setPerRefFormacaoSelectedError(true);
    if (
      iniciativeSelected === null ||
      iniciativeSelected === undefined ||
      iniciativeSelected === "" ||
      iniciativeSelected === 0 ||
      iniciativeSelected === "0"
    )
      setIniciativeSelectedError(true);
    if (
      certTypeSelected === null ||
      certTypeSelected === undefined ||
      certTypeSelected === "" ||
      certTypeSelected === 0 ||
      certTypeSelected === "0"
    )
      setCertTypeSelectedError(true);
      // if (
      //   stateSelected === null ||
      //   stateSelected === undefined ||
      //   stateSelected === "" ||
      //   stateSelected === 0 ||
      //   stateSelected === "0"
      // )
      //   setStateSelectedError(true);
    if (startDate === null || startDate === undefined || startDate === "")
      setStartDateError(true);
    if (endDate === null || endDate === undefined || endDate === "")
      setEndDateError(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setValidatedDropdowns(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (Object.keys(trainingDropdown).length > 0 && trainingDropdown) {
        //List
        trainingDropdown.trainingAction.push({ label: "...", value: "0" });
        trainingDropdown.dominio.push({ label: "...", value: "0" });
        trainingDropdown.trainingEntity.push({ label: "...", value: "0" });
        trainingDropdown.reasonOfTraining.push({ label: "...", value: "0" });
        trainingDropdown.perRefFormacao.push({ label: "...", value: "0" });
        trainingDropdown.initiative.push({ label: "...", value: "0" });
        trainingDropdown.certType.push({ label: "...", value: "0" });
        // trainingDropdown.state.push({ label: "...", value: "0" });

        setTrainingActList(trainingDropdown.trainingAction);
        setDomainList(trainingDropdown.dominio);
        setTrainingEntList(trainingDropdown.trainingEntity);
        setReasonTrainingList(trainingDropdown.reasonOfTraining);
        setPerRefFormacaoList(trainingDropdown.perRefFormacao);
        setIniciativeList(trainingDropdown.initiative);
        setCertTypeList(trainingDropdown.certType);
        // setStateList(trainingDropdown.state);
      }
      if (data?.length > 0) {
        setNumberOfHours(data[0].numberOfHours);
        setComments(data[0].comments);

        const checkEmptyData = (data, setState) => {
          if (data === null || data === undefined) {
            data = "0";
            setState(data);
          } else {
            setState(data);
          }
        };

        checkEmptyData(data[0].trainingProgram, setTrainingActSelected);
        checkEmptyData(data[0].dominio, setDomainSelected);
        checkEmptyData(data[0].traningEntity, setTrainingEntSelected);
        checkEmptyData(data[0].reasonForTraining, setReasonTrainingSelected);
        checkEmptyData(data[0].perRefFormacao, setPerRefFormacaoSelected);
        checkEmptyData(data[0].initiative, setIniciativeSelected);
        checkEmptyData(data[0].certType, setCertTypeSelected);
        // checkEmptyData(data[0].state, setStateSelected);

        setCheckOnJob(data[0].trainingPeriod);
        setEmpCode(data[0].empCode);

        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            return set(date);
          }
        };

        checkDate(data[0].startDate, setStartDate);
        checkDate(data[0].startDate, setOldStartDate);
        checkDate(data[0].endDate, setEndDate);
      } else {
        setStartDate();
        setEndDate();
        setNumberOfHours("");
        setTrainingActSelected("");
        setDomainSelected("");

        setReasonTrainingSelected("");
        setPerRefFormacaoSelected("");
        setIniciativeSelected("");
        setCertTypeSelected("");
        // setStateSelected("");
        setTrainingActSelected("");
        setTrainingEntSelected("");

        setCheckOnJob("");
        setComments("");
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(trainingData);
    }
  }, [trainingDropdown, rowClicked, trainingData]);

  const formatTrainingProgram = (cell, row) => {
    if (
      cell !== null &&
      Object.keys(trainingDropdown).length > 0 &&
      trainingDropdown
    ) {
      for (let i = 0; i < trainingDropdown?.trainingAction?.length; i++) {
        if (cell == trainingDropdown.trainingAction[i].value) {
          return <p>{trainingDropdown.trainingAction[i].label}</p>;
        }
      }
    }
  };

  const formatTrainingEntity = (cell, row) => {
    if (
      cell !== null &&
      Object.keys(trainingDropdown).length > 0 &&
      trainingDropdown
    ) {
      for (let i = 0; i < trainingDropdown?.trainingEntity?.length; i++) {
        if (cell == trainingDropdown.trainingEntity[i].value) {
          return <p>{trainingDropdown.trainingEntity[i].label}</p>;
        }
      }
    }
  };

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const columns = [
    {
      dataField: "trainingProgram",
      text: t(`portalrh.trainingSheet.TrainingAction`),
      sort: true,
      formatter: formatTrainingProgram,
      style: { fontSize: "12px" },
    },
    {
      dataField: "traningEntity",
      text: t(`portalrh.trainingSheet.TrainingEntity`),
      sort: true,
      formatter: formatTrainingEntity,
      style: { fontSize: "12px" },
    },
    {
      dataField: "startDate",
      text: t(`portalrh.trainingSheet.StartDate`),
      sort: true,
      formatter: formatData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "endDate",
      text: t(`portalrh.trainingSheet.EndDate`),
      sort: true,
      formatter: formatData,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    setTrainingActSelectedError(false);
    setDomainSelectedError(false);
    setTrainingEntSelectedError(false);
    setReasonTrainingSelectedError(false);
    setPerRefFormacaoSelectedError(false);
    setIniciativeSelectedError(false);
    setCertTypeSelectedError(false);
    // setStateSelectedError(false);
    setStartDateError(false);
    setEndDateError(false);
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  const cleanInputs = () => {
    setStartDate();
    setEndDate();
    setNumberOfHours("");
    setTrainingActSelected("");
    setDomainSelected("");

    setReasonTrainingSelected("");
    setPerRefFormacaoSelected("");
    setIniciativeSelected("");
    setCertTypeSelected("");
    // setStateSelected("");
    setTrainingActSelected("");
    setTrainingEntSelected("");

    setCheckOnJob("");
    setComments("");
  };

  const trainingSheetObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    startDate: startDate,
    endDate: endDate,
    numberOfHours: numberOfHours,
    trainingProgram: trainingActSelected,
    dominio: domainSelected,
    traningEntity: trainingEntSelected,
    reasonForTraining: reasonTrainingSelected,
    perRefFormacao: perRefFormacaoSelected,
    initiative: iniciativeSelected,
    trainingPeriod: checkOnJob,
    comments: comments,
    certType: certTypeSelected,
    lepNum: rowCollaborator.funcionarioNum,
    empCode: empCode,
    oldStartDate: oldStartDate,
    // state: stateSelected,
  };

  const updateData = (bollean) => {
    dispatch(updateTraining(trainingSheetObject));
  };

  const createNewData = (bollean) => {
    setTrainingSheetPage(bollean);

    dispatch(createTraining(trainingSheetObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };

  const validateDropdowns = (isSelected, required) => {
    if (validatedDropdowns && required) {
      if (
        isSelected === "" ||
        isSelected === null ||
        isSelected === undefined ||
        isSelected === 0 ||
        isSelected === "0"
      ) {
        return "dropdown-margin dropdown-border-invalid";
      } else {
        return "dropdown-margin dropdown-border-valid";
      }
    }
    if (validatedDropdowns && !required) {
      return "dropdown-margin dropdown-border-valid";
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
          {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              trainingSheetPage={trainingSheetPage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              trainingSheetPage={trainingSheetPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="6" xl="5">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.TrainingAction`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(trainingActSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={trainingActList}
                handleSubmit={handleTrainingAct}
                defaultValue={trainingActSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: trainingActSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.TrainingAction`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6" xl="2">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.Domain`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(domainSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={domainList}
                handleSubmit={handleDomain}
                defaultValue={domainSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: domainSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.Domain`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6" xl="2">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.TrainingEntity`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(trainingEntSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={trainingEntList}
                handleSubmit={handleTrainingEnt}
                defaultValue={trainingEntSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: trainingEntSelectedError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.TrainingEntity`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6" xl="3">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.ReasonforFormation`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(reasonTrainingSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={reasonTrainingList}
                handleSubmit={handleReasonTraining}
                defaultValue={reasonTrainingSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: reasonTrainingSelectedError ? "block" : "none",
                }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.ReasonforFormation`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="12" xl="8">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.perRefFormation`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(perRefFormacaoSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={perRefFormacaoList}
                handleSubmit={handlePerRefFormacao}
                defaultValue={perRefFormacaoSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: perRefFormacaoSelectedError ? "block" : "none",
                }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.perRefFormation`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12" xl="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.iniciative`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(iniciativeSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={iniciativeList}
                handleSubmit={handleIniciative}
                defaultValue={iniciativeSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: iniciativeSelectedError ? "block" : "none",
                }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.iniciative`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="12" xl="6">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.certType`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(certTypeSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={certTypeList}
                handleSubmit={hanldeCertType}
                defaultValue={certTypeSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: certTypeSelectedError ? "block" : "none",
                }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.certType`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="4" xl="2">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.NºofHours`)} <b>*</b>
              </Form.Label>
              <Form.Control
                value={numberOfHours == null ? "" : numberOfHours}
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                onChange={handleNumbHours}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback type="invalid">
                {`O campo ${t(
                  `portalrh.trainingSheet.NºofHours`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {/* <Col md="4" xl="2">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.state`)} <b>*</b>
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(stateSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={stateList}
                handleSubmit={hanldeState}
                defaultValue={stateSelected}
                disabled={isClicked ? false : true}
                required
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: stateSelectedError ? "block" : "none",
                }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.state`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> */}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col md="4" xl="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.StartDate`)} <b>*</b>
              </Form.Label>
              <DateTimePicker
                value={startDate}
                onChange={handleStartDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? validateDropdowns(startDate, true)
                    : "form-control"
                }
                disabled={isClicked ? false : true}
                required={true}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: startDateError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.StartDate`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4" xl="4">
            <Form.Group controlId="validationCustom01">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.trainingSheet.EndDate`)} <b>*</b>
              </Form.Label>
              <DateTimePicker
                value={endDate}
                onChange={handleEndDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? validateDropdowns(endDate, true)
                    : "form-control"
                }
                disabled={isClicked ? false : true}
                required={true}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{ display: endDateError ? "block" : "none" }}
              >
                {" "}
                {`O campo ${t(
                  `portalrh.trainingSheet.EndDate`
                )} é de preenchimento obrigatório`}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="12">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.trainingSheet.Comments`)}
            </Form.Label>
            <Form.Control
              value={comments == null ? "" : comments}
              className={
                isClicked
                  ? "collaboratorRH__FormControl2"
                  : "collaboratorRH__FormControl"
              }
              type="text"
              onChange={handleComments}
              disabled={isClicked ? false : true}
            />
          </Col>
          <Form.Check
            className="rhcollaborator__labels__"
            type="radio"
            label={t(`portalrh.trainingSheet.OnJob`)}
            name="group1"
            checked={checkOnJob === "1"}
            style={{ paddingBottom: 10 }}
            onChange={handleCheck}
            disabled={isClicked ? false : true}
            value="1"
          />
          <Form.Check
            className="rhcollaborator__labels__"
            type="radio"
            label={t(`portalrh.trainingSheet.AfterWork`)}
            name="group1"
            checked={checkOnJob === "0"}
            style={{ paddingBottom: 10 }}
            onChange={handleCheck}
            disabled={isClicked ? false : true}
            value="0"
          />
          <Form.Check
            className="rhcollaborator__labels__"
            type="radio"
            label={t(`portalrh.trainingSheet.Mixed`)}
            name="group1"
            checked={checkOnJob === "2"}
            style={{ paddingBottom: 10 }}
            onChange={handleCheck}
            disabled={isClicked ? false : true}
            value="2"
          />
        </div>

        <RhTableList
          tableId={"38"}
          columns={columns}
          data={trainingData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhTrainingSheet);
