import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Button, Col, Accordion } from "react-bootstrap";
import InputDate from "~/pages/Easypay/components/InputDate";
import Dropdown from "~/components/Dropdown";
import DatePicker from "~/components/DatePicker";
import { withNamespaces } from "react-i18next";
import { v4 as uuid } from "uuid";

const Filters = ({
  filtersToAdd,
  activeKeys,
  filterState,
  setFilterState,
  handleSearch,
  translations,
  selectedItem,
  setCurrentList,
  t,
}) => {
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let language = userSettings?.language ? userSettings.language : "pt";

  const handleReset = () => {
    setFilterState({});
    setCleanDropdown(true);
  };

  const handleInputChange = (key, value) => {
    setCurrentList([]);
    if (key === "dates") {
      if (
        value[0] !== null &&
        value[0] !== undefined &&
        value[1] !== null &&
        value[1] !== undefined
      ) {
        const date1 = new Date(value[0]);
        const date2 = new Date(value[1]);
        const dia = date1.getDate().toString().padStart(2, "0");
        const mes = (date1.getMonth() + 1).toString().padStart(2, "0");
        const ano = date1.getFullYear();
        const dia2 = date2.getDate().toString().padStart(2, "0");
        const mes2 = (date2.getMonth() + 1).toString().padStart(2, "0");
        const ano2 = date2.getFullYear();

        const dataFormatadaInicio = `${dia}/${mes}/${ano}`;
        const dataFormatadaFim = `${dia2}/${mes2}/${ano2}`;

        setFilterState((prevState) => ({
          ...prevState,
          [`${key}Inicio`]: dataFormatadaInicio,
          [`${key}Fim`]: dataFormatadaFim,
        }));
      }
    } else {
      setFilterState((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  const FilterComponents = {
    intervalDate: InputDate,
    dropdown: Dropdown,
    text: Form.Control,
    singleDate: DatePicker,
  };

  const renderFilter = (key) => {
    const filter = filtersToAdd[key];
    const FilterComponent = FilterComponents[filter.type];
    const lingua = language.trim();
    const filterName = translations[lingua][filter.label];

    if (!FilterComponent) {
      return null;
    }

    if (filter.type === "dropdown") {
      filter.list = filter.list.map((item) => {
        const translatedLabel = translations[lingua][item.label];
        return {
          ...item,
          label: translatedLabel ? translatedLabel : item.label,
        };
      });
    }
    return (
      <Form.Group controlId={key}>
        <Form.Label>{filterName}</Form.Label>
        <FilterComponent
          {...(filter.type === "intervalDate"
            ? {
                text: "",
                errorMessage: t(
                  "portalrh.abscenses.validateForm.requiredField"
                ),
                type: "created_at",
                placeholder: "",
                required: true,
                handleSubmit: (dates) => handleInputChange(key, dates),
                clean: cleanDropdown,
              }
            : {})}
          {...(filter.type === "dropdown"
            ? {
                placeholder: t("portalrh.abscenses.validateForm.selectOpt"),
                emptyDefault: true,
                required: true,
                resetDropdown: cleanDropdown,
                setResetDropdown: setCleanDropdown,
                list: filtersToAdd[key].list || [],
                handleSubmit: (submitValue) =>
                  handleInputChange(key, submitValue),
              }
            : {})}
          {...(filter.type === "text"
            ? {
                type: "text",
                value: filterState[key] || "",
                onChange: (e) => handleInputChange(key, e.target.value),
              }
            : {})}
          {...(filter.type === "singleDate"
            ? {
                value: filterState[key] || null,
                onChange: (date) => handleInputChange(key, date),
                format: "dd/MM/yyyy",
              }
            : {})}
        />
      </Form.Group>
    );
  };

  return (
    <Accordion activeKey={activeKeys?.filter}>
      <Accordion.Collapse eventKey="0">
        <Form>
          <>
            <Row>
              {Object.keys(filtersToAdd).map((key) => (
                <Col lg="4">{renderFilter(key)}</Col>
              ))}
            </Row>
            <Row>
              <Col>
                <Button className="card-button" onClick={() => handleSearch()}>
                  {t("general.search")}
                </Button>
                <Button
                  className="card-button"
                  onClick={() => {
                    handleReset();
                  }}
                  style={{ marginLeft: "15px" }}
                >
                  {t("general.clean")}
                </Button>
              </Col>
            </Row>
          </>
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(Filters);
