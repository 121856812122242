import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Card, Row, Col, Button, Alert } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { isEmpty } from "lodash";
import HelperModal from "./HelperModal";
import Messages from "~/components/Messages";
import FilePreviewCard from "~/pages/DataCapture/components/FilePreviewCard";
import FilePreview from "~/pages/DataCapture/components/FilePreview";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Dropdown from "~/components/Dropdown";
import { DocumentStatus } from "~/pages/DataCapture/utils/DataCaptureEnum";
import {
  saveDocumentIntoBatch,
  saveDocumentListIntoBatch,
  addDocumentToBatch,
  clearSavedBatch,
  findAllBatchs,
} from "~/pages/DataCapture/actions";
import uuid from "uuid/v1";

const SUPPORTED_FILES_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
  "application/x-zip-compressed",
];

const AFileUpload = ({
  t,
  docType,
  experiencedUser,
  show,
  datacaptureConfiguration,
  handleCloseUploadModal,
  docTypeExtraConfiguration,
}) => {
  const dispatch = useDispatch();

  const { batch, isLoadingBatch } = useSelector(
    (state) => state.dataCaptureReducer
  );
  const { openAIConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );

  const [warningMessage, setWarningMessage] = useState("");
  const [onDragOver, setOnDragOver] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [cleanDropdown, setCleanDropdown] = useState(false);

  const inputFile = useRef(null);
  const { documents } = batch || [];

  const [maxFileSize, setMaxFileSize] = useState(1024 * 1024 * 15); //15 Mb
  const [maxFiles, setMaxFiles] = useState(experiencedUser ? 100 : 1);
  const [datasetSelected, setDatasetSelected] = useState({});
  const [selectedDocType, setSelectedDocType] = useState("");
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (!isLoadingBatch && batch && batch.id && !experiencedUser) {
      dispatch(clearSavedBatch());
      handleOpenHelperModal();
    }
  }, [batch, isLoadingBatch]);

  useEffect(() => {
    setDocs([]);
    if (
      Object.keys(openAIConfiguration).length > 0 &&
      openAIConfiguration.datasets &&
      openAIConfiguration.datasets.length > 0
    ) {
      if (docTypeExtraConfiguration.length === 1) {
        const dataset = openAIConfiguration.datasets.find(
          (dataset) =>
            dataset.documentType &&
            dataset.documentType.includes(docTypeExtraConfiguration[0])
        );
        setDatasetSelected(dataset);
        dispatch(clearSavedBatch());
      }
    }
  }, [docTypeExtraConfiguration, openAIConfiguration]);

  const getMaxFiles = () => {
    if (experiencedUser) {
      const config =
        datacaptureConfiguration &&
        datacaptureConfiguration.find((conf) => conf.documentType === docType);
      if (config && config.numberOfFiles) {
        return config.numberOfFiles;
      } else return maxFiles;
    } else return 1;
  };

  const addFiles = (file) => {
    //used to upload one file at a time
    if (documents && documents.length < maxFiles) {
      if (SUPPORTED_FILES_TYPES.includes(file.type)) {
        if (file.size < maxFileSize) uploadFile(file);
        else {
          setWarningMessage(
            file.name + ": " + t("datacapture.general.largeFile")
          );
          setShowWarning(true);
          setTimeout(() => setShowWarning(false), 10000);
        }
      }
    } else {
      setWarningMessage(t("datacapture.general.tooManyFiles"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  let showComponent = "none";
  if (show || !experiencedUser) {
    showComponent = "block";
  }

  function uploadFile(file) {
    //used to upload one file at a time
    const id = batch ? batch.id : null;
    const flow = docType;
    const batchDTO = {
      id,
      flow,
      document: {
        file,
        status: DocumentStatus.UPLOADING,
      },
      docType: datasetSelected.documentType,
    };
    // if (datasetSelected.documentType !== undefined) {
    dispatch(saveDocumentIntoBatch(batchDTO));
    // } else {
    //   dispatch(addDocumentToBatch(docs.document));
    // }
  }

  const handleOnDrop = (event) => {
    setOnDragOver(false);
    const files_ = event.dataTransfer.files;
    const files = [];
    for (let i = 0; i < files_.length; i++) {
      files.push(files_.item(i));
    }
    handleMultipleFiles(files);
    setDocs((prevDocs) => [...prevDocs, ...files]);
    event.preventDefault();
  };

  const isMobile = window.innerWidth < 992 ? true : false;

  const handleOnChoose = (event) => {
    if (event.target.multiple === true) {
      const files_ = event.target.files;
      const files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_.item(i));
      }
      handleMultipleFiles(files);
      setDocs((prevDocs) => [...prevDocs, ...files]);
    } else {
      addFiles(event.target.files[0]);
    }
  };

  const handleDocTypeChange = (event) => {
    setSelectedDocType(event);
    const dataset = openAIConfiguration.datasets.find(
      (dataset) => dataset.documentType && dataset.documentType.includes(event)
    );
    setDatasetSelected(dataset);
    dispatch(clearSavedBatch());
  };

  const handleMultipleFiles = (files) => {
    const handleSaveDocs = (files) => {
      const docList = [];
      files.forEach((file) => {
        const id = batch ? batch.id : null;
        const flow = docType;
        docList.push({
          id,
          flow,
          document: {
            file,
            status: DocumentStatus.UPLOADING,
          },
          docType: datasetSelected.documentType,
        });
      });
      if (datasetSelected.documentType !== undefined) {
        for (let i = 0; i < docList.length; i++) {
          dispatch(addDocumentToBatch(docList[i].document));
        }
        //dispatch(saveDocumentListIntoBatch(docList));
      } else {
        for (let i = 0; i < docList.length; i++) {
          dispatch(addDocumentToBatch(docList[i].document));
        }
      }
    };

    if (files.length <= getMaxFiles()) {
      if (files.some((file) => !SUPPORTED_FILES_TYPES.includes(file.type))) {
        setWarningMessage(t("datacapture.general.checkFileType"));
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 10000);
      } else {
        let totalfilesize = files.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          0
        );
        if (totalfilesize > maxFileSize) {
          setWarningMessage(
            "It was not possible to download all files. Total size of files is bigger than 15 Mb."
          );
          setShowWarning(true);
          setTimeout(() => setShowWarning(false), 10000);
          let size = 0;
          let files_ = [];
          files.forEach((file) => {
            size += file.size;
            if (size < maxFileSize) files_.push(file);
          });
          files_.length > 0 && handleSaveDocs(files_);
        } else {
          handleSaveDocs(files);
        }
      }
    } else {
      setWarningMessage(t("datacapture.general.tooManyFiles"));
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 10000);
    }
  };

  const handleMultipleFilesSend = (files) => {
    const docList = [];
    files.forEach((file) => {
      const id = batch ? batch.id : null;
      const flow = docType;
      docList.push({
        id,
        flow,
        document: {
          file,
          status: DocumentStatus.UPLOADING,
        },
        docType: datasetSelected.documentType,
      });
    });
    if (datasetSelected.documentType !== undefined) {
      for (let i = 0; i < docList.length; i++) {
        dispatch(saveDocumentListIntoBatch([docList[i]]));
      }
    }
  };

  const handleClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const clearUploadedFiles = () => {
    dispatch(clearSavedBatch());
    setWarningMessage("");
    experiencedUser && handleCloseUploadModal();
  };

  const [showHelperModal, setShowHelperModal] = useState(false);
  const handleCloseHelperModal = () => {
    setShowHelperModal(false);
  };
  const handleOpenHelperModal = () => {
    setShowHelperModal(true);
  };
  const stylePadding = () => {
    return experiencedUser ? "0px" : "";
  };

  const renderDocTypeOptions = () =>
    docTypeExtraConfiguration.map((docType, index) => {
      return {
        label: docType, // Use docType as the label
        value: docType, // Use docType as the value
        selected: false,
      };
    });
    
  const chooseButtonProceed = () => {
    if (experiencedUser) {
      if (docTypeExtraConfiguration.length > 1 && datasetSelected !== "") {
        return (
          <Button
            className="card-button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleMultipleFilesSend(docs);
              handleCloseUploadModal();
              setCleanDropdown(true);
            }}
          >
            {t("datacapture.general.confirm")}
          </Button>
        );
      } else if (batch && batch.id) {
        return (
          <Button
            className="card-button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              dispatch(findAllBatchs());
              dispatch(clearSavedBatch());
              handleCloseUploadModal();
              setCleanDropdown(true);
            }}
          >
            {t("datacapture.general.confirm")}
          </Button>
        );
      } else if (docs.length > 0) {
        return (
          <Button
            className="card-button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              handleMultipleFilesSend(docs);
              handleCloseUploadModal();
              dispatch(findAllBatchs());
              dispatch(clearSavedBatch());
              setCleanDropdown(true);
            }}
          >
            {t("datacapture.general.confirm")}
          </Button>
        );
      } else {
        return (
          <Button
            disabled
            variant="secondary"
            className="card-button"
            style={{ marginLeft: "10px" }}
          >
            {t("datacapture.general.confirm")}
          </Button>
        );
      }
    }
  };

  return (
    <>
      <div
        className="main-card-v2"
        style={{ padding: stylePadding(), display: showComponent }}
      >
        <Card bsPrefix="card-flat">
          <Card.Header
            className="justify-content-between"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={isMobile ? { fontSize: "18px" } : { margin: "0" }}>
              {" "}
              {documents && documents.length === 0
                ? t("datacapture.general.fileUploadTitle")
                : experiencedUser
                ? t("datacapture.general.chooseConfirmToProceed")
                : t("datacapture.general.fileUploadTitle")}
            </h4>
          </Card.Header>
          <Card.Body style={{ display: "flex", flexDirection: "column" }}>
            {showWarning && (
              <Alert
                variant="warning"
                onClose={() => setShowWarning(false)}
                dismissible
              >
                <strong>Alert: </strong>
                {warningMessage}
              </Alert>
            )}

            {!isLoadingBatch && (
              <Row className="justify-content-md-center">
                {documents && documents.length < maxFiles && (
                  <Col sm={"6"} className="uploadArea">
                    {documents &&
                      documents.length === 0 &&
                      !experiencedUser && (
                        <div
                          style={{ textAlign: "center", paddingBottom: "15px" }}
                        >
                          <h5>{t("datacapture.general.uploadToProceed")}</h5>
                        </div>
                      )}

                    <div
                      className="dropArea"
                      onDrop={(e) => handleOnDrop(e)}
                      onClick={() => handleClick()}
                      onDragEnter={() => setOnDragOver(true)}
                      onDragLeave={() => setOnDragOver(false)}
                      style={
                        onDragOver
                          ? { background: "#fff" }
                          : { background: "#efefef" }
                      }
                    >
                      <span>
                        <i className="icon-submit dc-image-upload-icon" />
                        <div className="dc-label-main">
                          {t("datacapture.general.dropToUpload")}
                        </div>
                      </span>
                      <input
                        multiple={experiencedUser ? true : false}
                        id="file"
                        type="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          experiencedUser
                            ? handleOnChoose(e)
                            : addFiles(e.target.files[0])
                        }
                      />
                    </div>
                    <div className="dc-label">
                      {"Total file size 15 Mb, " +
                        t("datacapture.general.maxFiles") +
                        " " +
                        getMaxFiles() || maxFiles}
                    </div>
                    <small>{t("datacapture.general.supportedFiles")}</small>
                  </Col>
                )}
                {!isEmpty(docs) && (
                  <>
                    <Col sm="6" className="selectedFiles">
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {docs &&
                          docs.map((document, index) => {
                            return (
                              <Col
                                lg="6"
                                key={uuid()}
                                style={{ marginTop: 20 }}
                              >
                                <FilePreview
                                  document={document}
                                  index={index}
                                  statusFile={"LOADED"}
                                  setDocs={setDocs}
                                />
                              </Col>
                            );
                          })}
                      </Row>
                      {docTypeExtraConfiguration.length > 1 && (
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Col lg="6" key={uuid()} style={{ marginTop: 20 }}>
                            <Form.Label>
                              {t("datacapture.metadata.document_type")}
                            </Form.Label>
                            <Dropdown
                              list={renderDocTypeOptions()}
                              handleSubmit={handleDocTypeChange}
                              emptyDefault={true}
                              resetDropdown={cleanDropdown}
                              setResetDropdown={setCleanDropdown}
                              flowId={selectedDocType}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            )}
          </Card.Body>
          <Card.Footer style={{ minHeight: "50px" }}>
            {chooseButtonProceed()}
          </Card.Footer>
        </Card>
        <Messages
          afterHideSuccess={clearUploadedFiles}
          afterHideError={clearUploadedFiles}
        />
      </div>
      <HelperModal
        showHelperModal={showHelperModal}
        handleCloseHelperModal={handleCloseHelperModal}
      />
    </>
  );
};
export default withNamespaces()(AFileUpload);
