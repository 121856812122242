import { combineReducers } from "redux";

import { toastsReducer } from "~/components/Toast/reducers";
import { messagesReducer } from "~/components/Messages/reducers";
import { adminConfigReducer } from "~/pages/Admin/SystemSettings/reducers";
import { loginReducer } from "~/pages/Login/reducers";
import { userManagementReducer } from "~/pages/Admin/User/reducers";
import { globalReducer } from "~/containers/Layout/reducers";
import { userSettingsReducer } from "~/pages/User/reducers";
import { chatReducer } from "~/components/chat/reducers";
import { roleReducer } from "~/pages/Admin/Role/reducers";
import { ruleReducer } from "~/pages/Admin/Rules/reducers";
import { permissionReducer } from "~/pages/Admin/Permission/reducers";
import { dataCaptureReducer } from "~/pages/DataCapture/reducers";
//import { validprocsReducer } from "~/pages/ProcessValidation/reducers";
import { mfilesReducer } from "~/pages/DataCapture/components/MFiles/reducers";

//Ducks
import { applicationReducer } from "../ducks/application";
import {budgetManagementCRUDReducer} from "../ducks/budgetManagementCRUD"
import { applicationMenuReducer } from "../ducks/applicationMenu";
import { resetPasswordReducer } from "../ducks/resetPassword";
import { udwReducer } from "../ducks/udw";
import { legacyPermissionReducer } from "../ducks/legacyPermission";
import { oauthReducer } from "../ducks/oauth";
import { notificationsReducer } from "../ducks/notifications";
import { tasksReducer } from "../ducks/tasks";
import { processesReducer } from "../ducks/processes";
import { systemSettingsApplicationsReducer } from "../ducks/systemSettings/applications";
import { iflowReportsReducer } from "../ducks/iflowReports";
import { organizationsReducer } from "../ducks/organizations";
import { datacaptureConfigurationReducer } from "../ducks/datacaptureConfiguration";
import { reportReducer } from "../ducks/reports";
import { portalrhReducer } from "../ducks/portalrh";
import { onboardingReducer } from "../ducks/onboarding";
import { budgetManagementReducer } from "../ducks/budgetManagement";
import { claimsManagementReducer } from "../ducks/claimsManagement";
import { onboardingSettingsReducer } from "../ducks/onboardingSettings";
import { registerOrgAdminReducer } from "../ducks/registerOrgAdmin";
import { easypayReducer } from "../ducks/easypay";
import { portalRhMenuReducer } from "../ducks/portalRhMenu";
import { taskFoldersReducer } from "../ducks/taskFolders";
import { processAnnotationsReducer } from "../ducks/processAnnotations";
import { iflowReducer } from "../ducks/iflow";
import { budgetmgrConfigurationReducer } from "../ducks/budgetmgrConfiguration";
import { rhadminConfigurationReducer } from "../ducks/rhadminConfiguration";
import { personalReducer } from "../ducks/collaboratorPersonalData";
import { simulatorReducer } from "../ducks/simulator";
import {siadapraReducer} from "../ducks/siadapra";

import { webTimeDataReducer } from "../ducks/webTimeWidget";
import { delegacoesReducer } from "../ducks/delegacoes";
import { processesSignatureReducer } from "../ducks/processesSignature";
import { openaiReducer } from "../ducks/openai";
const appReducer = combineReducers({
  //Ducks
  applicationReducer,
  applicationMenuReducer,
  resetPasswordReducer,
  udwReducer,
  legacyPermissionReducer,
  oauthReducer,
  notificationsReducer,
  tasksReducer,
  processesReducer,
  systemSettingsApplicationsReducer,
  iflowReportsReducer,
  organizationsReducer,
  datacaptureConfigurationReducer,
  reportReducer,
  portalrhReducer,
  onboardingReducer,
  onboardingSettingsReducer,
  registerOrgAdminReducer,
  easypayReducer,
  portalRhMenuReducer,
  taskFoldersReducer,
  processAnnotationsReducer,
  budgetManagementReducer,
  iflowReducer,
  budgetmgrConfigurationReducer,
  rhadminConfigurationReducer,
  siadapraReducer,
  personalReducer,
  simulatorReducer,
  webTimeDataReducer,
  processesSignatureReducer,
  claimsManagementReducer,
  budgetManagementCRUDReducer,
  openaiReducer,

  //Old way
  toastsReducer,
  messagesReducer,
  adminConfigReducer,
  loginReducer,
  userManagementReducer,
  globalReducer,
  userSettingsReducer,
  chatReducer,
  // adminMenuReducer,
  roleReducer,
  ruleReducer,
  permissionReducer,
  dataCaptureReducer,
  //validprocsReducer,
  mfilesReducer,
  delegacoesReducer,
});

export default appReducer;
