import React, { useState, useEffect } from "react";
import "../assets/scss/_liftworldTable.scss";
import LiftworldTableInput from "./LiftworldTableInput";
import Dropdown from "./TableInputDropdown";
import uuid from "uuid/v1";
import iconMinus from "~/pages/Liftworld/assets/img/minus-icon.png";
import TableTextbox from "./TableTextbox";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllWorkplans,
  saveProvider,
  getAllProviders,
  setProvider,
  saveProvidersData,
} from "~/store/ducks/budgetManagement/actionTypes";
import InputSearch from "~/pages/Liftworld/components/InputSearch";
import Input from "./Input";
import Modal from "~/pages/Easypay/components/Modal";
import ModalProvider from "./ModalNewProviderSimple";
import { activeError, activeSuccess } from "~/components/Messages/actions";
const devLogConsole = require("~/utils/devLog");
const LiftworldSuppliersTable = ({
  list,
  typeList,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
}) => {
  const dispatch = useDispatch();

  const {
    workplans,
    providers,
    providerTypes,
    departments,
    provider,
    companies,
  } = useSelector((state) => state.budgetManagementReducer);

  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [newClientName, setNewClientName] = useState("");
  const [newClientAddress, setNewClientAddress] = useState("");
  const [newClientPayment, setNewClientPayment] = useState("");

  const [currentSupplierIndex, setCurrentSupplierIndex] = useState();
  const [updateHoursCalculations, setUpdateHoursCalculations] = useState(false);

  useEffect(() => {
    devLogConsole(provider);
    dispatch(getAllProviders(workplans[workplanIndex].organizationId));
    if (
      provider?.numFornecedor != undefined &&
      provider?.idFornecedor != undefined &&
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores[currentSupplierIndex]
    ) {
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].fornecedores[currentSupplierIndex].fornecedorForn =
        provider.idFornecedor;
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].fornecedores[
        currentSupplierIndex
      ].prazoPagamentoDias = provider.prazoPagamento;
      dispatch(setAllWorkplans(workplans));
      setProvider({});
    }
  }, [provider]);

  function addNewProvider() {
    dispatch(
      saveProvider({
        nome: newClientName,
        prazoPagamento: newClientPayment,
        morada: newClientAddress,
        orgId: workplans[workplanIndex].organizationId,
      })
    );
    setNewClientName("");
    setNewClientAddress("");
    setIsModalErrorShowing(false);
  }

  function buildNameArray(info) {
    var newArray = [];
    for (var i = 0; i < info.length; i++) {
      newArray.push({
        label: info[i].nome,
        value: info[i].nome,
        selected: false,
      });
    }
    return newArray;
  }
  function buildInputField(text) {
    return {
      value: text,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: "",
    };
  }

  function removeRow(index) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    var newArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores;
    newArray.splice(index, 1);
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].fornecedores = newArray;

    var newOptionCostValue = 0;
    var newOptionSaleValue = 0;
    const currentHoras =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas;
    const currentForn =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores;
    const currentDespesas =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].despesas;
    for (var i = 0; i < currentHoras.length; i++) {
      newOptionCostValue += parseFloat(currentHoras[i].custosHoras);
      newOptionSaleValue += parseFloat(currentHoras[i].valorVendaHoras);
    }
    for (var i = 0; i < currentForn.length; i++) {
      newOptionCostValue += parseFloat(currentForn[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentForn[i].valorVendaForn);
    }
    for (var i = 0; i < currentDespesas.length; i++) {
      newOptionCostValue += parseFloat(currentDespesas[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentDespesas[i].valorVendaDesp);
    }

    if (!(newOptionCostValue instanceof String))
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].valorCustoOpt = newOptionCostValue?.toFixed(2);

    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].valorVendaOpt = newOptionSaleValue?.toFixed(2);

    var currentActivityCost = 0;
    var currentActivitySale = 0;

    var currentItemCost = 0;
    var currentItemSale = 0;

    const currentOptions =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].selected) {
        currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
        currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
      }
    }

    if (currentActivityCost === 0 && currentActivitySale === 0) {
      currentActivityCost = parseFloat(currentOptions[0].valorCustoOpt);
      currentActivitySale = parseFloat(currentOptions[0].valorVendaOpt);
    }

    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorCustoAct = currentActivityCost;
    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorVendaAct = currentActivitySale;

    const currentActivities =
      workplans[workplanIndex].items[itemIndex].atividades;

    for (var i = 0; i < currentActivities.length; i++) {
      currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
      currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
    }

    workplans[workplanIndex].items[itemIndex].valorCustoObj =
      parseFloat(currentItemCost);
    workplans[workplanIndex].items[itemIndex].valorVendaObj =
      parseFloat(currentItemSale);

    dispatch(setAllWorkplans(workplans));
  }

  function getIdFromName(e, name) {
    if (name === "fornecedorForn") {
      const prov = providers.find((x) => x.nome === e);
      if (prov != null) return prov?.idFornecedor;
    } else if (name === "tipoFornecedor") {
      const provType = providerTypes.find((x) => x.nome === e);
      if (provType != null) return provType?.idTipoFornecedor;
    } else if (name === "departamentoForn") {
      const dep = departments.find((x) => x.nome === e);
      if (dep != null) return dep?.idDepartamento;
    }
  }

  function handleTableChange(e, currentIndex, name) {
    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].fornecedores[currentIndex][name] = getIdFromName(
        e,
        name
      );
      const currentVendaForn =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].fornecedores[currentIndex].precoVendaForn;
      if (
        name === "tipoFornecedor" &&
        (currentVendaForn === undefined ||
          currentVendaForn === 0 ||
          currentVendaForn === "")
      ) {
        const provType = providerTypes.find(
          (x) => x.idTipoFornecedor === parseInt(getIdFromName(e, name))
        );
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].fornecedores[currentIndex].margemForn =
          provType.percentagem;
      }
      dispatch(setAllWorkplans(workplans));
    }
  }
  function getPosition(index) {
    if (index === 0) return "start";
    if (index === list.length - 1) return "end";
  }

  function convertIdToName(id, name) {
    if (name === "providers") {
      const prov = providers.find((x) => x.idFornecedor === parseInt(id));
      if (prov != null) return prov?.nome;
    } else if (name === "providerTypes") {
      const provType = providerTypes.find(
        (x) => x.idTipoFornecedor === parseInt(id)
      );
      if (provType != null) return provType?.nome;
    } else if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function handleFornChange(e, currentIndex, name) {
    if (e === "Novo fornecedor") {
      setIsModalErrorShowing(true);
      return;
    }
    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].fornecedores[currentIndex][name] = getIdFromName(
        e,
        name
      );
      const forn = providers.find(
        (x) => x.idFornecedor === parseInt(getIdFromName(e, name))
      );
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].fornecedores[currentIndex].prazoPagamentoDias =
        Math.max(forn.prazoPagamento, workplans[workplanIndex].prazoPagamento);
      dispatch(setAllWorkplans(workplans));
    }
  }

  function isAboveDaysLimit(currentIndex) {
    const currentPrazo =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores[currentIndex].prazoPagamentoDias;
    const prazo = workplans[workplanIndex].prazoPagamento;

    if (parseInt(currentPrazo) < parseInt(prazo)) {
      return "red";
    }
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper">
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="liftworld-label-wrapper">Nome</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientName)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientNameAdd"}
                setNewClientName={setNewClientName}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Morada</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientAddress)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientAddressAdd"}
                setNewClientAddress={setNewClientAddress}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "30px",
            }}
          >
            <div className="liftworld-label-wrapper">Prazo</div>
            <div style={{ width: "82%" }}>
              <Input
                field={buildInputField(newClientPayment)}
                key={uuid()}
                color={"activity"}
                size={"large-size"}
                name={"clientPaymentAdd"}
                setNewClientPayment={setNewClientPayment}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "30px",
            }}
          >
            {" "}
            <SimpleButton
              text={"Adicionar"}
              variant={"liftworld-button-primary"}
              onClick={() => addNewProvider()}
            />
          </div>
        </div>
      </div>
    );
  }

  const modalIsClosedProvider = (bollean) => {
    setIsModalErrorShowing(bollean);
  };
  const [newProvider, setNewProvider] = useState({});

  const [companiesList, setCompaniesList] = useState([]);

  const takeNewProvider = (data) => {
    setNewProvider(data);
  };

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (Object.keys(newProvider).length > 0) {
      newProvider.orgId = user?.organization?.id;
      newProvider.year = new Date().getFullYear();
      dispatch(saveProvidersData(newProvider));
    }
  }, [newProvider]);

  useEffect(() => {
    if (Object.keys(companies).length > 0) {
      const listFiltered = companies.filter((e) => e.organizationId);

      const newArray = listFiltered.map(function (e) {
        return {
          label: e.codigoEmpresa,
          value: e.organizationId.toString(),
        };
      });
      setCompaniesList(newArray);
    }
  }, [companies]);

  return (
    <div className="lifworld-table-wrapper">
      <ModalProvider
        isClicked={isModalErrorShowing}
        isClosed={modalIsClosedProvider}
        takeNewProvider={takeNewProvider}
        companiesList={companiesList}
      />
      <div className="liftworld-table-header-wrapper">
        {list &&
          Object.keys(list).length !== 0 &&
          list.map((item, index) => {
            return (
              <div
                className={
                  index === 5 || index == 8 || index === 9
                    ? "liftworld-table-7-wrapper"
                    : index < 2 || index === 3
                    ? "liftworld-table-12-wrapper"
                    : index === 2
                    ? "liftworld-table-115-wrapper"
                    : "liftworld-table-10-wrapper"
                }
              >
                <div
                  className={
                    `${"liftworld-table-header-element"} ` + getPosition(index)
                  }
                >
                  {item}
                </div>
              </div>
            );
          })}
      </div>
      <div>
        {typeList &&
          Object.keys(typeList).length !== 0 &&
          typeList.map((item, index) => {
            return (
              <div className="liftworld-table-header-wrapper">
                <div
                  className={`liftworld-table-12-wrapper ${
                    item.tipoFornecedor === 0 ? "invalid" : ""
                  }`}
                >
                  <Dropdown
                    text={""}
                    required={true}
                    list={buildNameArray(providerTypes)}
                    initialSelected={convertIdToName(
                      item.tipoFornecedor,
                      "providerTypes"
                    )}
                    handleSubmit={(e) => {
                      handleTableChange(e, index, "tipoFornecedor");
                    }}
                    position={index === typeList.length - 1 ? "start" : ""}
                    size={"normal"}
                  />
                </div>
                <div
                  className={`liftworld-table-12-wrapper ${
                    item.fornecedorForn === 0 ? "invalid" : ""
                  }`}
                  onClick={() => setCurrentSupplierIndex(index)}
                >
                  <InputSearch
                    text={""}
                    required={true}
                    list={buildNameArray(providers)}
                    handleSubmit={(e) => {
                      handleFornChange(e, index, "fornecedorForn");
                    }}
                    initialSelected={convertIdToName(
                      item.fornecedorForn,
                      "providers"
                    )}
                    position={"middle"}
                    size={"normal"}
                  />
                </div>
                <div
                  className={`liftworld-table-115-wrapper ${
                    item.departamentoForn === 0 ? "invalid" : ""
                  }`}
                >
                  {" "}
                  <Dropdown
                    text={""}
                    required={true}
                    list={buildNameArray(departments)}
                    handleSubmit={(e) => {
                      handleTableChange(e, index, "departamentoForn");
                    }}
                    initialSelected={convertIdToName(
                      item.departamentoForn,
                      "departments"
                    )}
                    position={"middle"}
                    size={"normal"}
                  />
                </div>
                <div className="liftworld-table-12-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.descricaoProdServ)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"descricaoProdServ"}
                    tableType={"fornecedores"}
                    workplanIndex={workplanIndex}
                    updateHoursCalculations={updateHoursCalculations}
                    setUpdateHoursCalculations={setUpdateHoursCalculations}
                  />
                </div>
                <div
                  className={`liftworld-table-10-wrapper ${
                    item.precoCompraForn === 0 || item.precoCompraForn === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  <LiftworldTableInput
                    field={buildInputField(item.precoCompraForn)}
                    position={"middle"}
                    pattern={"[0-9]*.?[0-9]"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"precoCompraForn"}
                    tableType={"fornecedores"}
                    workplanIndex={workplanIndex}
                    number={"number"}
                    updateHoursCalculations={updateHoursCalculations}
                    setUpdateHoursCalculations={setUpdateHoursCalculations}
                  />
                </div>
                <div className="liftworld-table-7-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.margemSemPrecoVendaForn)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"margemSemPrecoVendaForn"}
                    tableType={"fornecedores"}
                    workplanIndex={workplanIndex}
                    number={"number"}
                    updateHoursCalculations={updateHoursCalculations}
                    setUpdateHoursCalculations={setUpdateHoursCalculations}
                  />
                </div>
                <div
                  className={`liftworld-table-10-wrapper ${
                    item.precoVendaForn === 0 || item.precoVendaForn === ""
                      ? "invalid"
                      : ""
                  }`}
                >
                  <LiftworldTableInput
                    field={buildInputField(item.precoVendaForn)}
                    position={"middle"}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"precoVendaForn"}
                    workplanIndex={workplanIndex}
                    tableType={"fornecedores"}
                    number={"number"}
                    updateHoursCalculations={updateHoursCalculations}
                    setUpdateHoursCalculations={setUpdateHoursCalculations}
                  />
                </div>
                <div className="liftworld-table-10-wrapper">
                  <TableTextbox
                    value={
                      isNaN(parseFloat(item.valorVendaForn)) ||
                      item.valorVendaForn === undefined
                        ? ""
                        : numberWithCommas(
                            parseFloat(item.valorVendaForn).toFixed(2)
                          ) + "€"
                    }
                    position={"middle"}
                    color={"colored"}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-table-7-wrapper">
                  <TableTextbox
                    value={
                      isNaN(parseFloat(item.margemForn)) ||
                      item.margemForn === undefined
                        ? ""
                        : numberWithCommas(
                            parseFloat(item.margemForn).toFixed(2)
                          ) + "%"
                    }
                    position={"middle"}
                    color={"colored"}
                    number={"number"}
                  />
                </div>
                <div className="liftworld-table-7-wrapper">
                  <LiftworldTableInput
                    field={buildInputField(item.prazoPagamentoDias)}
                    position={index === typeList.length - 1 ? "end" : ""}
                    size="normal"
                    itemIndex={itemIndex}
                    activityIndex={activityIndex}
                    optionIndex={optionIndex}
                    currentIndex={index}
                    name={"prazoPagamentoDias"}
                    tableType={"fornecedores"}
                    workplanIndex={workplanIndex}
                    fontColor={isAboveDaysLimit(index)}
                    number={"number"}
                    updateHoursCalculations={updateHoursCalculations}
                    setUpdateHoursCalculations={setUpdateHoursCalculations}
                  />
                </div>
                <div className="liftworld-minus-icon">
                  <img
                    src={iconMinus}
                    alt="Icon Check"
                    className={"liftworld-minus-icon"}
                    onClick={() => removeRow(index)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default LiftworldSuppliersTable;
