import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { DateTime } from "luxon";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import {
  getAcademicQualifications,
  getAcademicDropdown,
  updateAcademicQualifications,
  createAcademicQualifications,
  updateNotMainAcademicQualifications,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";
import Messages from "~/components/Messages";
import UploadDocument from "../UploadDocuments";
import ButtonsCreateUpdate from "./ButtonsCreateUpdate";

const RhAcademicQualifications = ({ t, rowCollaborator }) => {
  const [pessoalCode, setPessoalCode] = useState("");
  const [thematicField, setThematicField] = useState("");
  const [comments, setComments] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [degreeOriginList, setDegreeOriginList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [educationalInstituteList, setEducationalInstituteList] = useState([]);
  const [TeachingEstablishmentList, setTeachingEstablishmentList] = useState(
    []
  );
  const [formationList, setFormationList] = useState([]);
  const [europianLevelList, setEuropianLevelList] = useState([]);
  const [levelBSocialList, setLevelBSocialList] = useState([]);
  const [degreeSelected, setDegreeSelected] = useState("");
  const [degreeOriginSelected, setDegreeOriginSelected] = useState("");
  const [courseSelected, setCourseSelected] = useState("");
  const [countrySelected, setCountrySelected] = useState("");
  const [specializationSelected, setSpecializationSelected] = useState("");
  const [educationalInstituteSelected, setEducationalInstituteSelected] =
    useState("");
  const [teachingEstablishmentSelected, setTeachingEstablishmenSelected] =
    useState("");
  const [formationSelected, setFormationSelected] = useState("");
  const [europianLevelSelected, setEuropianLevelSelected] = useState("");
  const [levelBSocialSelected, setLevelBSocialSelected] = useState("");
  const [checkOnGoing, setCheckOnGoing] = useState(false);
  const [checkForeign, setCheckForeign] = useState(false);
  const [checkMain, setCheckMain] = useState(false);
  const [rowClicked, setRowClicked] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [htlId, setHtlId] = useState("");
  const [academicQualificatonsPage, setAcademicQualificationsPage] =
    useState(false);
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);
  const [degreeSelectedError, setDegreeSelectedError] = useState(false);
  const [degreeOriginSelectedError, setDegreeOriginSelectedError] =
    useState(false);
  const [courseSelectedError, setCourseSelectedError] = useState(false);
  const [countrySelectedError, setCountrySelectedError] = useState(false);
  const [
    educationalInstituteSelectedError,
    setEducationalInstituteSelectedError,
  ] = useState(false);
  const [
    teachingEstablishmentSelectedError,
    setTeachingEstablishmentSelectedError,
  ] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [europianLevelSelectedError, setEuropianLevelSelectedError] =
    useState(false);

  const [isNew, setIsNew] = useState(false);

  const {
    academicQualifications,
    academicDropdown,
    isLoadingUpdateAcademicQualifications,
  } = useSelector((state) => state.personalReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(getAcademicDropdown());
      dispatch(
        getAcademicQualifications(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      setIsNew(false);
      setRowClicked([]);
      setAcademicQualificationsPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateAcademicQualifications]);

  const handleDegree = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setDegreeSelectedError(false);
    setDegreeSelected(e);
  };

  const handleThematicField = (e) => {
    setThematicField(e.target.value);
  };

  const handlePessoalCode = (e) => {
    setPessoalCode(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setEndDateError(false);
    setEndDate(e);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleDegreOrigin = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setDegreeOriginSelectedError(false);
    setDegreeOriginSelected(e);
  };

  const handleCourse = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setCourseSelectedError(false);
    setCourseSelected(e);
  };
  const handleCountry = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setCountrySelectedError(false);
    setCountrySelected(e);
  };

  const handleSpecialization = (e) => {
    setSpecializationSelected(e);
  };

  const handleEducationalInstitut = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setEducationalInstituteSelectedError(false);
    setEducationalInstituteSelected(e);
  };

  const handleTeachingEstablishment = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setTeachingEstablishmentSelectedError(false);
    setTeachingEstablishmenSelected(e);
  };

  const handleFormation = (e) => {
    setFormationSelected(e);
  };

  const handleEuropianLevel = (e) => {
    if (e !== null || e !== undefined || e !== "" || e !== 0 || e !== "0")
      setEuropianLevelSelectedError(false);
    setEuropianLevelSelected(e);
  };

  const handleLevelBSocial = (e) => {
    setLevelBSocialSelected(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (
      degreeSelected === "" ||
      degreeSelected === null ||
      degreeSelected === undefined ||
      degreeSelected === 0 ||
      degreeSelected === "0"
    )
      setDegreeSelectedError(true);
    if (
      degreeOriginSelected === "" ||
      degreeOriginSelected === null ||
      degreeOriginSelected === undefined ||
      degreeOriginSelected === 0 ||
      degreeOriginSelected === "0"
    )
      setDegreeOriginSelectedError(true);
    if (
      courseSelected === "" ||
      courseSelected === null ||
      courseSelected === undefined ||
      courseSelected === 0 ||
      courseSelected === "0"
    )
      setCourseSelectedError(true);
    if (
      countrySelected === "" ||
      countrySelected === null ||
      countrySelected === undefined ||
      countrySelected === 0 ||
      countrySelected === "0"
    )
      setCountrySelectedError(true);
    if (
      educationalInstituteSelected === "" ||
      educationalInstituteSelected === null ||
      educationalInstituteSelected === undefined ||
      educationalInstituteSelected === 0 ||
      educationalInstituteSelected === "0"
    )
      setEducationalInstituteSelectedError(true);
    if (
      teachingEstablishmentSelected === "" ||
      teachingEstablishmentSelected === null ||
      teachingEstablishmentSelected === undefined ||
      teachingEstablishmentSelected === 0 ||
      teachingEstablishmentSelected === "0"
    )
      setTeachingEstablishmentSelectedError(true);
    if (
      endDate === "" ||
      endDate === null ||
      endDate === undefined ||
      endDate === 0 ||
      endDate === "0"
    )
      setEndDateError(true);
    if (
      europianLevelSelected === "" ||
      europianLevelSelected === null ||
      europianLevelSelected === undefined ||
      europianLevelSelected === 0 ||
      europianLevelSelected === "0"
    )
      setEuropianLevelSelectedError(true);
    if (form.checkValidity() === false) {
      setValidated(true);
      setValidatedDropdowns(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  const handleCheckMainAndGoing = (e, condition) => {
    switch (condition) {
      case "onGoing":
        setCheckOnGoing(e.target.value);
        setCheckMain("0");
        break;
      case "main":
        setCheckMain(e.target.value);
        setCheckOnGoing("0");
        break;
      default:
        setCheckMain("0");
        setCheckOnGoing("0");
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (Object.keys(academicDropdown).length > 0 && academicDropdown) {
        //labels from null values from db
        academicDropdown.degreeCode.push({ label: "...", value: "0" });
        academicDropdown.degreeOriginCode.push({ label: "...", value: "0" });
        academicDropdown.courseCode.push({ label: "...", value: "0" });

        academicDropdown.educationalInstitute.push({
          label: "...",
          value: 0,
        });
        academicDropdown.teachingEstablishment.push({
          label: "...",
          value: 0,
        });
        academicDropdown.europianLevel.push({ label: "...", value: "0" });
        academicDropdown.nivelBSocial.push({ label: "...", value: "0" });

        academicDropdown.specialization.push({ label: "...", value: "0" });
        academicDropdown.countryCode.push({ label: "...", value: "0" });

        setDegreeList(academicDropdown?.degreeCode);
        setCountryList(academicDropdown?.countryCode);
        setDegreeOriginList(academicDropdown?.degreeOriginCode);
        setCourseList(academicDropdown?.courseCode);
        setSpecializationList(academicDropdown?.specialization);
        setEducationalInstituteList(academicDropdown?.educationalInstitute);
        setTeachingEstablishmentList(academicDropdown?.teachingEstablishment);
        setEuropianLevelList(academicDropdown?.europianLevel);
        setLevelBSocialList(academicDropdown?.nivelBSocial);
        //O label vem a null da bd
        // setFormationList(
        //   dropdown?.formation?.label === null
        //     ? dropdown?.formation
        //     : "Data Not Found "
        // );
      }
      if (data?.length > 0) {
        setThematicField(data[0].thematicArea ? data[0].thematicArea : "");
        setPessoalCode(data[0].personalCodeQ ? data[0].personalCodeQ : "");
        setComments(data[0].comments ? data[0].comments : "");

        //check if value is empty
        const checkEmptyData = (data, setState) => {
          if (data === null || data === undefined) {
            data = "0";
            setState(data);
          } else {
            setState(data);
          }
        };

        checkEmptyData(data[0].specialization, setSpecializationSelected);
        checkEmptyData(data[0].degree, setDegreeSelected);
        checkEmptyData(data[0].degreeOrigin, setDegreeOriginSelected);
        checkEmptyData(data[0].course, setCourseSelected);
        checkEmptyData(data[0].country, setCountrySelected);
        checkEmptyData(
          data[0].educationalInstitute,
          setEducationalInstituteSelected
        );
        checkEmptyData(data[0].teachingEstab, setTeachingEstablishmenSelected);
        checkEmptyData(data[0].formation, setFormationSelected);
        checkEmptyData(data[0].europeanLvl, setEuropianLevelSelected);
        checkEmptyData(data[0].hblCode, setLevelBSocialSelected);
        checkEmptyData(data[0].hblCode, setLevelBSocialSelected);

        setHtlId(data[0].htlId);
        setCheckMain(data[0].main);
        setCheckOnGoing(data[0].ongoing);

        if (data[0].foreingLicence == "1") {
          setCheckForeign(true);
        } else {
          setCheckForeign(false);
        }

        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            return set(date);
          }
        };

        checkDate(data[0].startDate, setStartDate);
        checkDate(data[0].endDate, setEndDate);
      } else {
        setSpecializationSelected("");
        setDegreeSelected("");
        setDegreeOriginSelected("");
        setCourseSelected("");
        setCountrySelected("");
        setEducationalInstituteSelected("");
        setTeachingEstablishmenSelected("");
        setFormationSelected("");
        setEuropianLevelSelected("");
        setLevelBSocialSelected("");
        setThematicField("");
        setPessoalCode("");
        setComments("");
        setStartDate("");
        setEndDate("");
        setCheckMain("0");
        setCheckForeign("");
        setCheckOnGoing("0");
        setCleanDropdown(true);
      }
    };
    if (rowClicked.length > 0) {
      dataHandling(rowClicked);
    } else {
      dataHandling([]);
    }
  }, [academicQualifications, academicDropdown, rowClicked, rowCollaborator]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatEndData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const formatWithCheckIcon = (cell) => {
    if (cell == "1") {
      return <p>&#10003;</p>;
    }
  };

  const formatDegreeLabel = (cell, row) => {
    if (
      cell !== null &&
      Object.keys(academicDropdown).length > 0 &&
      academicDropdown
    ) {
      for (let i = 0; i < academicDropdown?.degreeCode?.length; i++) {
        if (cell == academicDropdown.degreeCode[i].value) {
          return <p>{academicDropdown.degreeCode[i].label}</p>;
        }
      }
    }
  };

  const formatDegreOriginLabel = (cell, row) => {
    if (
      cell !== null &&
      Object.keys(academicDropdown).length > 0 &&
      academicDropdown
    ) {
      for (let i = 0; i < academicDropdown?.courseCode?.length; i++) {
        if (cell == academicDropdown.courseCode[i].value) {
          return <p>{academicDropdown.courseCode[i].label}</p>;
        }
      }
    }
  };

  const columns = [
    {
      dataField: "degree",
      text: t(`portalrh.AcademicQualifications.Degree`),
      formatter: formatDegreeLabel,
      sort: true,
      style: { fontSize: "12px" },
    },
    {
      dataField: "course",
      text: t(`portalrh.AcademicQualifications.Course`),
      sort: true,
      formatter: formatDegreOriginLabel,
      style: { fontSize: "12px" },
    },
    {
      dataField: "startDate",
      text: t(`portalrh.AcademicQualifications.StartDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "endDate",
      text: t(`portalrh.AcademicQualifications.EndDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      dataField: "main",
      text: t(`portalrh.AcademicQualifications.Main`),
      formatter: formatWithCheckIcon,
      style: { fontSize: "12px" },
    },
  ];

  const OnTakingBolean = (data) => {
    setDegreeSelectedError(false);
    setDegreeOriginSelectedError(false);
    setCourseSelectedError(false);
    setCountrySelectedError(false);
    setEducationalInstituteSelectedError(false);
    setTeachingEstablishmentSelectedError(false);
    setEndDateError(false);
    setEuropianLevelSelectedError(false);
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }

  const cleanInputs = () => {
    setSpecializationSelected("");
    setDegreeSelected("");
    setDegreeOriginSelected("");
    setCourseSelected("");
    setCountrySelected("");
    setEducationalInstituteSelected("");
    setTeachingEstablishmenSelected("");
    setFormationSelected("");
    setEuropianLevelSelected("");
    setLevelBSocialSelected("");
    setThematicField("");
    setPessoalCode("");
    setComments("");
    setStartDate("");
    setEndDate("");
    setCheckMain("0");
    setCheckForeign("");
    setCheckOnGoing("0");
    setCleanDropdown(true);
  };

  const academicQualObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    comments: comments,
    course: courseSelected,
    country: countrySelected,
    degree: degreeSelected,
    degreeOrigin: degreeOriginSelected,
    educationalInstitute: educationalInstituteSelected,
    endDate: endDate,
    foreingLicence: checkForeign ? "1" : "",
    formation: formationSelected,
    main: checkMain,
    ongoing: checkOnGoing,
    personalCodeQ: pessoalCode,
    specialization: specializationSelected,
    startDate: startDate,
    teachingEstab: teachingEstablishmentSelected,
    europeanLvl: europianLevelSelected,
    thematicArea: thematicField,
    htlId: htlId,
    pssNum: rowCollaborator.pssNum,
  };

  const notMainAcademicQualObject = {
    htlId: htlId,
    pssNum: rowCollaborator.pssNum,
    main: checkMain,
  };

  const updateData = (bollean) => {
    setAcademicQualificationsPage(bollean);
    setIsClicked(false);
    if (isNew) {
      delete academicQualObject.htlId;
      dispatch(createAcademicQualifications(academicQualObject));
    } else {
      delete academicQualObject.pssNum;
      dispatch(updateAcademicQualifications(academicQualObject));
    }
    if (academicQualObject.main === "1") {
      dispatch(updateNotMainAcademicQualifications(notMainAcademicQualObject));
    }
  };

  const createNewData = (bollean) => {
    setAcademicQualificationsPage(bollean);
    delete academicQualObject.htlId;

    dispatch(createAcademicQualifications(academicQualObject));

    if (academicQualObject.main === "1") {
      dispatch(updateNotMainAcademicQualifications(notMainAcademicQualObject));
    }
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      setNewButtonClicked(true);
      cleanInputs();
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };

  const validateDropdowns = (isSelected, required) => {
    if (validatedDropdowns && required) {
      if (
        isSelected === "" ||
        isSelected === null ||
        isSelected === undefined ||
        isSelected === 0 ||
        isSelected === "0"
      ) {
        return "dropdown-margin dropdown-border-invalid";
      } else {
        return "dropdown-margin dropdown-border-valid";
      }
    }
    if (validatedDropdowns && !required) {
      return "dropdown-margin dropdown-border-valid";
    }
  };

  return (
    <>
      <div className="collaboratoRH__view">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="buttons__rhAdmin">
            <ButtonsCreateUpdate
              isClicked={isClicked}
              setIsClicked={setIsClicked}
              setRowClicked={setRowClicked}
              setIsNew={setIsNew}
              cleanInputs={cleanInputs}
            />
            {/* {!updateButtonClicked ? (
              <ButtonsCreate
                createNewData={createNewData}
                OnTakingBolean={OnTakingBolean}
                handleNewButtonClicked={handleNewButtonClicked}
                academicQualificatonsPage={academicQualificatonsPage}
              />
            ) : null}
            {!newButtonClicked ? (
              <ButtonsUpdate
                OnTakingBolean={OnTakingBolean} 
                updateData={updateData}
                academicQualificatonsPage={academicQualificatonsPage}
                handleUpdateButtonClicked={handleUpdateButtonClicked}
                isClicked={isClickedBtnUpdate}
                setIsClicked={setIsClickedBtnUpdate}
              />
            ) : null} */}
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="6">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.Degree`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(degreeSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={degreeList}
                  handleSubmit={handleDegree}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={degreeSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: degreeSelectedError ? "block" : "none" }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.Degree`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="6">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.Course`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(courseSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={courseList}
                  handleSubmit={handleCourse}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={courseSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: courseSelectedError ? "block" : "none" }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.Course`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="6">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.Country`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(countrySelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={countryList}
                  handleSubmit={handleCountry}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={countrySelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: countrySelectedError ? "block" : "none" }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.Country`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="6">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.DegreeOrigin`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(degreeOriginSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={degreeOriginList}
                  handleSubmit={handleDegreOrigin}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={degreeOriginSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: degreeOriginSelectedError ? "block" : "none",
                  }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.DegreeOrigin`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="6">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.Formation`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={formationList}
                handleSubmit={handleFormation}
                resetDropdown={cleanDropdown}
                setResetDropdown={setCleanDropdown}
                defaultValue={formationSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="6">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.Specialization`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={specializationList}
                handleSubmit={handleSpecialization}
                defaultValue={specializationSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="6">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.EducationalInst`)}{" "}
                  <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(educationalInstituteSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={educationalInstituteList}
                  handleSubmit={handleEducationalInstitut}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={educationalInstituteSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: educationalInstituteSelectedError
                      ? "block"
                      : "none",
                  }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.EducationalInst`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="12">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.TeachingEstab`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(teachingEstablishmentSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={TeachingEstablishmentList}
                  handleSubmit={handleTeachingEstablishment}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={teachingEstablishmentSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: teachingEstablishmentSelectedError
                      ? "block"
                      : "none",
                  }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.TeachingEstab`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="2" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.ThematicArea`)}
              </Form.Label>
              <Form.Control
                value={thematicField}
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                onChange={handleThematicField}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col lg="2" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.StaffCode`)}
              </Form.Label>
              <Form.Control
                value={pessoalCode}
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                onChange={handlePessoalCode}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col lg="2" md="4">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.EuropeanLevel`)} <b>*</b>
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(europianLevelSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={europianLevelList}
                  handleSubmit={handleEuropianLevel}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  defaultValue={europianLevelSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: europianLevelSelectedError ? "block" : "none",
                  }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.EuropeanLevel`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg="2" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.LevelBSocial`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={levelBSocialList}
                handleSubmit={handleLevelBSocial}
                resetDropdown={cleanDropdown}
                setResetDropdown={setCleanDropdown}
                defaultValue={levelBSocialSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col lg="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.StartDate`)}
              </Form.Label>
              <DateTimePicker
                value={startDate}
                onChange={handleStartDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col lg="4" md="4">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.AcademicQualifications.EndDate`)} <b>*</b>
                </Form.Label>
                <DateTimePicker
                  value={endDate}
                  onChange={handleEndDate}
                  locale="pt-PT"
                  format="dd/MM/yyyy"
                  className={
                    isClicked && validatedDropdowns
                      ? validateDropdowns(endDate, true)
                      : "form-control"
                  }
                  disabled={isClicked ? false : true}
                  required={true}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: endDateError ? "block" : "none" }}
                >
                  {" "}
                  {`O campo ${t(
                    `portalrh.AcademicQualifications.EndDate`
                  )} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
          <div>
            <Form.Check
              className="rhcollaborator__labels__"
              inline
              type="radio"
              name="group1"
              label={t(`portalrh.AcademicQualifications.Ongoing`)}
              checked={checkOnGoing === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleCheckMainAndGoing(e, "onGoing")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              inline
              type="radio"
              name="group1"
              label={t(`portalrh.AcademicQualifications.Main`)}
              checked={checkMain === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleCheckMainAndGoing(e, "main")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              inline
              type="checkbox"
              label={t(`portalrh.AcademicQualifications.ForeignLicense`)}
              checked={checkForeign}
              style={{ paddingBottom: 10 }}
              onChange={() => setCheckForeign((checkForeign) => !checkForeign)}
              disabled={isClicked ? false : true}
            />
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col lg="12">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.AcademicQualifications.Comments`)}
              </Form.Label>
              <Form.Control
                value={comments}
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                onChange={handleComments}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div>
            <UploadDocument
              isClicked={isClicked}
              uploadFile={academicQualificatonsPage}
              rowCollaborator={rowCollaborator}
              id={3}
            />
          </div>

          <RhTableList
            tableId={"31"}
            columns={columns}
            data={academicQualifications}
            onClickedRow={takeClickedRow}
          />
        </Form>
        <Messages />
      </div>
    </>
  );
};
export default withNamespaces()(RhAcademicQualifications);
