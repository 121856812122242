import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { transformFormData } from "../utils";
import { postIflowFormData } from "~/store/ducks/processes/actionTypes";
import oauth from "~/utils/oauth";
import DatePicker from "~/components/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/pt-br";
import customLocalePt from "./customLocale";
import "dayjs/locale/es";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";
import { useLabelWidth } from "../utils/useLabelWidth";
import { useLabelFormated } from "../utils/useLabelFormated";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { parse, format } from "date-fns";
import { useAutoAdjust } from "../utils/useAutoAdjust";

const CreateDateInput = (props) => {
  const {
    field,
    isChild,
    children,
    justifyContent,
    styleSheet,
    fontSize,
    newDatePicker,
    translations,
  } = props;
  const dispatch = useDispatch();

  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  const [dateValue, setDateValue] = useState("");
  const [required, setRequired] = useState(false);
  const [localeTranslations, setlocaleTranslations] = useState("en");

  const { labelWidthClass, labelWidth } = useLabelWidth(field);
  const { labelFormated } = useLabelFormated(field, translations);

  const { autoAdjust } = useAutoAdjust(field);

  useEffect(() => {
    if (userSettings && userSettings.language != undefined) {
      if (userSettings.language.trim() == "pt") setlocaleTranslations("pt-br");
      if (userSettings.language.trim() == "es") setlocaleTranslations("es");
      if (userSettings.language.trim() == "en") setlocaleTranslations("en");
    }
  }, [userSettings]);

  useEffect(() => {
    const parseDate = (value, dateFormat) => {
      try {
        return parse(value, dateFormat, new Date());
      } catch (error) {
        console.error("Erro ao analisar a data:", error);
        return null;
      }
    };
    
    const identifyDateFormat = (value) => {
      if (/^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{2}:\d{2}:\d{2} BST \d{4}$/.test(value)) {
        return "EEE MMM dd HH:mm:ss 'BST' yyyy";
      } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return "dd/MM/yyyy";
      } else if (/^\d{2}-\d{2}-\d{4}$/.test(value)) {
        return "dd-MM-yyyy";
      } else {
        return null;
      }
    };
    
    if (field?.value) {
      const inputDateFormat = identifyDateFormat(field.value);
      if (inputDateFormat) {
        const date = parseDate(field.value, inputDateFormat);
        if (date) {
          const outputDateFormat =  "yyyy/MM/dd";
          const formattedDate = format(date, outputDateFormat);
          setDateValue(formattedDate);
        }
      }
    }

    setRequired(field?.obligatory === "true");
  }, [field]);

  const handleDateChange = (value) => {
    if (field.onchange_submit && field.onchange_submit !== "") {
      if (field.onchange_submit.includes("document.dados.op.value='")) {
        const array = field.onchange_submit.split("document.dados.op.value='");
        if (array) {
          const array2 = array[1].split("'");
          if (array2) {
            document.dados.op.value = array2[0];
          }
        }
      }
      if (field.onchange_submit.includes("document.dados.submit()")) {
        const formdata = transformFormData(
          new FormData(document.getElementsByName("dados")[0])
        );
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postIflowFormData(formdata, url));
      }
    } else {
      setDateValue(value);
    }
  };
  const isMobile = window.innerWidth < 992 ? true : false;

  const getLocaleText = () => {
    let lang = enUS;
    if (userSettings?.language.trim() === "pt") {
      lang = ptBR;
    }
    if (userSettings?.language.trim() === "es") {
      lang = esES;
    }
    return lang.components.MuiLocalizationProvider.defaultProps.localeText;
  };
  return (
    <>
      <Form.Group
        controlId={`formDate-${field.variable}`}
        as={Row}
        style={{
          flexWrap: "nowrap",
          marginLeft: "0px",
          marginRight: "0px",
          marginBottom: styleSheet?.compact ? "5px" : "10px",
          flexGrow: "1",
        }}
      >
        {!isChild ? (
          <Form.Label
            column
            className={
              `${labelWidthClass ? labelWidthClass : "iflow-form-label-fix"} ${field.disabled === "false" ? "borderOnNotDisabledFields" : ""}`
            }
            style={
              labelWidth
                ? {
                    width: labelWidth,
                    minWidth: labelWidth,
                    flexBasis: labelWidth,
                  }
                : {}
            }
          >
            {labelFormated
              ? labelFormated
              : field.text && field.text.b
              ? field.text.b
              : field.text}
          </Form.Label>
        ) : (
          <Form.Label
            column
            style={{
              flexGrow: "1",
              //paddingTop: field.disabled === "true" ? "0" : "0.375rem",
              //paddingBottom: field.disabled === "true" ? "0" : "0.375rem",
              paddingTop: "0.375rem",
              paddingBottom: "0.375rem",
            }}
          >
            {labelFormated
              ? labelFormated
              : field.text && field.text.b
              ? field.text.b
              : field.text}
          </Form.Label>
        )}
        <Col
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            flexGrow: "2",
            minWidth: "fit-content",
          }}
        >
          {field.disabled === "true" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                justifyContent: justifyContent ? justifyContent : "",
              }}
            >
              {field.value !== "" && (
                <>
                  {newDatePicker ? (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={localeTranslations}
                      localeText={getLocaleText()}
                    >
                      <input
                        type="hidden"
                        name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                        value={dayjs(dateValue).format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        )}
                      />

                      <DateTimePicker
                        value={dayjs(dateValue)}
                        ampm={false}
                        onChange={(newValue) =>
                          setDateValue(new Date(dayjs(newValue)))
                        }
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        DateTimeFormat={Intl.DateTimeFormat}
                      />
                    </LocalizationProvider>
                  ) : (
                    <DatePicker
                      value={dateValue}
                      name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                      format={
                        field.dateformattext
                          ? field.dateformattext
                          : "dd/MM/yyyy HH:mm:ss"
                      }
                      fontSize={fontSize}
                      className={
                        isMobile
                          ? "date-picker-selector"
                          : "date-picker-selector_rightSide"
                      }
                      autoAdjust={autoAdjust}
                      style={{ flexGrow: "1", flexBasis: "0", widht: "100%" }}
                      disabled
                    />
                  )}
                </>
              )}
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: isChild ? "5px" : "0",
                paddingRight: isChild ? "5px" : "0",
                justifyContent: justifyContent ? justifyContent : "",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "auto",
                }}
              >
                {newDatePicker ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={localeTranslations}
                    localeText={getLocaleText()}
                  >
                    <input
                      type="hidden"
                      name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                      value={dayjs(dateValue).format(
                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                      )}
                    />

                    <DateTimePicker
                      value={dayjs(dateValue)}
                      ampm={false}
                      onChange={(newValue) =>
                        setDateValue(new Date(dayjs(newValue)))
                      }
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      DateTimeFormat={Intl.DateTimeFormat}
                    />
                  </LocalizationProvider>
                ) : (
                  <DatePicker
                    value={dateValue}
                    onChange={(value) => handleDateChange(value)}
                    name={`DateToTransform?dateformattext=${field.dateformattext}&variable=${field.variable}`}
                    format={
                      field.dateformattext
                        ? field.dateformattext
                        : "dd/MM/yyyy "
                    }
                    className={
                      isMobile
                        ? "date-picker-selector"
                        : "date-picker-selector_rightSide"
                    }
                    boolean={autoAdjust}
                    fontSize={fontSize}
                    style={{
                      flexGrow: "1",
                      flexBasis: "0",
                      right: "15px",
                      width: "100%",
                    }}
                  />
                )}
              </div>
              <Form.Control.Feedback type="invalid">
                {`O campo ${
                  field.text && field.text.b ? field.text.b : field.text
                } é de preenchimento obrigatório`}
              </Form.Control.Feedback>
              {children && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: "1",
                    width: "100%",
                  }}
                >
                  {children}
                </div>
              )}
            </div>
          )}
        </Col>
      </Form.Group>
      {styleSheet?.highLightRows && !isChild && !styleSheet?.ultraCompact && (
        <hr />
      )}
    </>
  );
};
export default CreateDateInput;
