import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  FIND_USAGE_REPORT,
  GET_REPORT_TO_EXTRACT
} from "../ducks/reports/actionTypes";

import {
  findUsageReport,
  getReportToExtract
} from "../ducks/reports/sagas";

export const reportSagas = [
  takeEvery(FIND_USAGE_REPORT, findUsageReport),
  takeLatest(GET_REPORT_TO_EXTRACT, getReportToExtract)
]
