import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { LuPinOff } from "react-icons/lu";
import { LuPin } from "react-icons/lu";

const MenuItemWithHover = ({
  groupID,
  groupDesignacao,
  handlePinUnpin,
  alreadyPinned,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <MenuItem
      key={groupID}
      value={groupID}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <span>{groupDesignacao}</span>
        <div
          onClick={(event) => {
            event.stopPropagation();
            handlePinUnpin(groupID);
          }}
        >
          {alreadyPinned.includes(groupID) ? (
            <LuPinOff style={{ color: "#3a6cc2" }} />
          ) : (
            isHovered && <LuPin style={{ color: "#3a6cc2" }} />
          )}
        </div>
      </Box>
    </MenuItem>
  );
};

export default MenuItemWithHover;
