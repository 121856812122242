import { reject } from "q";
import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import axios from "axios";
import oauth from "~/utils/oauth";

import {
  setRHAdminConfiguration,
  clearRHAdminConfiguration,
  sucessesPersistRHAdminConfiguration,
  setRHBolsaHorasConfiguration,
  clearRHBolsaHorasConfiguration,
  setRHBolsaHorasUsersGroup,
  clearRHBolsaHorasUsersGroup,
  clearRHSaldoBolsaHoras,
  setRHSaldoBolsaHoras,
  clearRHSaldoInicialFinalBolsaHoras,
  setRHSaldoInicialFinalBolsaHoras,
  sucessesPersistRHBolsaHorasConfiguration,
  setOpenAiConfiguration,
  sucessesPersisOpenAIConfiguration,
  clearOpenAiConfiguration,
  successDeleteOpenaiDocument,
  clearRHCurrencyConfiguration,
  setRHCurrencyConfiguration,
  sucessesPersistRHCurrencyConfiguration
} from "./actionTypes";
const devLogConsole = require("~/utils/devLog");
export function* getRHAdminConfiguration({ organizationId }) {
  try {
    yield put(clearRHAdminConfiguration());
    const resp = yield http.get(
      `/api/rhadmin-configuration/find-by-organization?orgId=${organizationId}`
    );
    yield put(setRHAdminConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRHAdminConfiguration({ configuration }) {
  try {
    devLogConsole(configuration, typeof configuration);
    yield put(clearRHAdminConfiguration());
    yield http.put("/api/rhadmin-configuration/create", configuration);
    yield put(sucessesPersistRHAdminConfiguration());
  } catch (error) {
    reject(error);
  }
}

//CURRENCY CONFIGURATION

export function* getRHCurrencyConfiguration({ organizationId }) {
  try {
    yield put(clearRHCurrencyConfiguration());
    const resp = yield http.get(
      `/api/configuration-currency/find-by-organization?orgId=${organizationId}`
    );
    yield put(setRHCurrencyConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRHCurrencyConfiguration({ configuration }) {
  try {
    yield put(clearRHCurrencyConfiguration());
    yield http.put("/api/configuration-currency/save", configuration);
    yield put(sucessesPersistRHCurrencyConfiguration());
  } catch (error) {
    reject(error);
  }
}

// BOLSA DE HORAS

export function* getRHBolsaHorasConfiguration({ organizationId }) {
  try {
    yield put(clearRHBolsaHorasConfiguration());
    const resp = yield http.get(
      `/api/configuration-flow/find-by-organization?orgId=${organizationId}`
    );
    yield put(setRHBolsaHorasConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHBolsaHorasUsersGroup() {
  try {
    yield put(clearRHBolsaHorasUsersGroup());
    const resp = yield http.get(
      `/api/get-group-users-ausencias`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRHBolsaHorasUsersGroup(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHSaldoBolsaHoras({ usrId }) {
  try {
    yield put(clearRHSaldoBolsaHoras());
    const resp = yield http.get(
      `/api/get-saldo-by-usr-id?usrId=${usrId}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRHSaldoBolsaHoras(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHSaldoInicialFinalBolsaHoras({ usrId, data }) {
  try {
    yield put(clearRHSaldoInicialFinalBolsaHoras());
    const resp = yield http.get(
      `/api/get-saldo-by-id?usrId=${usrId}&date=${data}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRHSaldoInicialFinalBolsaHoras(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRHBolsaHorasConfiguration({ configuration }) {
  try {
    yield put(clearRHBolsaHorasConfiguration());
    yield http.put("/api/configuration-flow/save", configuration);
    yield put(sucessesPersistRHBolsaHorasConfiguration());
  } catch (error) {
    reject(error);
  }
}

export function* getOpenAIConfiguration({ organizationId }) {
  try {
    yield put(clearOpenAiConfiguration());
    const token = oauth.getAccessToken(); // get the access token
    const resp = yield call(
      http.get,
      `/api/openai-configuration/find-by-organization/${organizationId}`,
      { headers: { Authorization: `Bearer ${token}` } } // add the token to the headers
    );
    yield put(setOpenAiConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistOpenAIConfiguration({ configuration }) {
  try {
    const token = oauth.getAccessToken();
    if (configuration && configuration.id) {
      yield call(http.put, `/api/openai-configuration/update`, configuration, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      yield call(http.post, "/api/openai-configuration/create", configuration, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    yield put(sucessesPersisOpenAIConfiguration());
  } catch (error) {
    reject(error);
  }
}

export function* deleteOpenAiConfiguration({ id }) {
  try {
    const token = oauth.getAccessToken();
    yield call(http.delete, `/api/document-dataset/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(successDeleteOpenaiDocument());
  } catch (error) {
    reject(error);
  }
}
