import {
  POST_OPENAI_CHAT,
  GET_OPENAI_CHAT,
  SET_OPENAI_CHAT,
  GET_OPENAI_CHAT_LIST,
  SET_OPENAI_CHAT_LIST,
  SET_SAVE_DOCUMENTEXPENSES_LOADING,
  SET_DOCUMENT_EXPENSES_RESULT,
  POST_IFLOW_FORM_EXPENSES,
  SET_ALERT_DOCUMENT_EXPENSE,
  GET_DOCUMENT_EXPENSES_LIST,
  SET_DOCUMENT_EXPENSES_LIST,
  GET_DOCUMENT_EXPENSES_SPECIFIC,
  SET_DOCUMENT_EXPENSES_SPECIFIC,
  SET_CREATE_DOCUMENT_EXPENSES,
  SET_GENERATE_PROMPT,
  SET_PID_EXPENSES,
} from "./actionTypes";

const initialState = {
  isLoadingOpenAiChat: false,
  openAiChat: {},
  openAiChatList: {},
  isLoadingSaveDocumentExpenses: false,
  documentExpensesResult: {},
  alertDocumentExpense: "",
  documentExpensesList: [],
  documentExpensesSpecific: {},
  documentExpensesSaved: "",
  documentExpensesSavedId: null,
  generatePromptResult: "",
  pidExpenses: {},
};

export function openaiReducer(state = initialState, action) {
  switch (action.type) {
    case POST_OPENAI_CHAT:
      return { ...state };
    case GET_OPENAI_CHAT:
      return { ...state, isLoadingOpenAiChat: true };
    case SET_OPENAI_CHAT:
      return {
        ...state,
        isLoadingOpenAiChat: false,
        openAiChat: action.openAiChat,
      };
    case GET_OPENAI_CHAT_LIST:
      return { ...state };
    case SET_OPENAI_CHAT_LIST:
      return {
        ...state,
        openAiChatList: action.openAiChatList,
      };

    case SET_SAVE_DOCUMENTEXPENSES_LOADING:
      return {
        ...state,
        isLoadingSaveDocumentExpenses: action.loading,
      };
    case SET_PID_EXPENSES:
      return {
        ...state,
        pidExpenses: action.pid,
      };
    case SET_DOCUMENT_EXPENSES_RESULT:
      return {
        ...state,
        documentExpensesResult: action.expense,
        isLoadingSaveDocumentExpenses: false,
      };
    case SET_GENERATE_PROMPT:
      return {
        ...state,
        generatePromptResult: action.prompt,
        isLoadingSaveDocumentExpenses: false,
      };
    case POST_IFLOW_FORM_EXPENSES:
      return { ...state };
    case SET_ALERT_DOCUMENT_EXPENSE:
      return {
        ...state,
        alertDocumentExpense: action.alert,
        isLoadingSaveDocumentExpenses: false,
      };
    case GET_DOCUMENT_EXPENSES_LIST:
      return { ...state };
    case SET_DOCUMENT_EXPENSES_LIST:
      return {
        ...state,
        documentExpensesList: action.list,
      };
    case GET_DOCUMENT_EXPENSES_SPECIFIC:
      return { ...state };
    case SET_DOCUMENT_EXPENSES_SPECIFIC:
      return {
        ...state,
        documentExpensesSpecific: action.doc,
      };
    case SET_CREATE_DOCUMENT_EXPENSES:
      return {
        ...state,
        documentExpensesSaved: action.message,
        documentExpensesSavedId: action.id,
        isLoadingSaveDocumentExpenses: false,
      };
    default:
      return state;
  }
}
