import { put, call } from "redux-saga/effects";
import http from "~/utils/config/http";
import axios from "axios";
import { activeError, activeSuccess } from "~/components/Messages/actions";
import { BPM_MIDDLEWARE, IFLOW_APP, RH_MIDDLEWARE } from "~/utils/constants";
import {
  setOpenAiChat,
  setOpenAiChatList,
  setSaveDocumentExpensesLoading,
  setPidExpenses,
  setDocumentExpensesResult,
  setAlertDocumentExpenses,
  setDocumentExpensesList,
  setDocumentExpensesSpecific,
  setCreateDocumentExpenses,
  setGeneratePrompt,
} from "./actionTypes";
import oauth from "~/utils/oauth";
const devLogConsole = require("~/utils/devLog");

export function* postOpenAiChat({ chat }) {
  try {
    const token = oauth.getAccessToken();
    yield call(http.post, "/api/openai/thread/save", chat, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    devLogConsole(e);
  }
}
export function* getOpenAiChat({ chatId }) {
  try {
    const token = oauth.getAccessToken();
    const response = yield call(
      http.get,
      `/api/openai/thread?threadId=${chatId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(setOpenAiChat(response.data));
  } catch (e) {
    devLogConsole(e);
  }
}

export function* getOpenAiChatsList({ userId }) {
  try {
    const token = oauth.getAccessToken();
    const response = yield call(
      http.get,
      `/api/openai/thread/list?userId=${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(setOpenAiChatList(response.data));
  } catch (e) {
    devLogConsole(e);
  }
}

export function* saveDocumentExpenses({ docList }) {
  const token = oauth.getAccessToken();
  try {
    yield put(setSaveDocumentExpensesLoading(true));
    const resp = yield call(
      http.post,
      "/api/openai/document/extract-data",
      docList,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(setDocumentExpensesResult(resp.data));
  } catch (e) {
    yield put(setSaveDocumentExpensesLoading(false));
  }
}

export function* generatePrompt({ items, desc = null }) {
  const token = oauth.getAccessToken();
  try {
    yield put(setSaveDocumentExpensesLoading(true));
    const datasetItems = Object.entries(items).map(([key, value]) => ({
      key,
      value,
    }));
    const body = { datasetItems, documentDesc: desc };
    const resp = yield call(http.post, "/api/openai/generate/prompt", body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(setGeneratePrompt(resp.data));
  } catch (e) {
    yield put(setGeneratePrompt(""));
    yield put(setSaveDocumentExpensesLoading(false));
  }
}

export function* generatePromptFromPrompts({ items, desc = null }) {
  const token = oauth.getAccessToken();
  try {
    yield put(setSaveDocumentExpensesLoading(true));
    const datasetItems = items;
    const body = { datasetItems, documentDesc: desc };
    const resp = yield call(http.post, "/api/openai/generate/prompt", body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(setGeneratePrompt(resp.data));
  } catch (e) {
    yield put(setGeneratePrompt(""));
    yield put(setSaveDocumentExpensesLoading(false));
  }
}

export function* postCreateDocumentExpenses({ payload }) {
  const token = oauth.getAccessToken();
  try {
    yield put(setSaveDocumentExpensesLoading(true));
    const resp = yield call(http.post, "/api/openai/document/save", payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(setCreateDocumentExpenses("success", resp.data));
  } catch (e) {
    yield put(setCreateDocumentExpenses("error", null));
  }
}

export function* postIflowFormExpenses({ payload, url }) {
  const token = oauth.getAccessToken();
  try {
    const resp = yield call(http.post, url, payload, IFLOW_APP, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put(setSaveDocumentExpensesLoading(false));
    yield put(setAlertDocumentExpenses("success"));
    yield put(setPidExpenses(resp.data));
  } catch (e) {
    yield put(setAlertDocumentExpenses("error"));
    yield put(setPidExpenses({}));
  }
}

export function* getDocumentExpensesList({ usrId }) {
  const token = oauth.getAccessToken();
  try {
    const resp = yield call(
      http.get,
      `/api/openai/document/list?userId=${usrId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(setDocumentExpensesList(resp.data));
  } catch (e) {
    yield put(setDocumentExpensesList([]));
  }
}

export function* getDocumentExpensesSpecific({ docId }) {
  const token = oauth.getAccessToken();
  try {
    const resp = yield call(
      http.get,
      `/api/openai/document?documentId=${docId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    yield put(setDocumentExpensesSpecific(resp.data));
  } catch (e) {
    yield put(setDocumentExpensesSpecific({}));
  }
}
