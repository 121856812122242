export const CLEAR_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RHADMIN_CONFIGURATION";
export const GET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@GET_RHADMIN_CONFIGURATION";
export const SET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SET_RHADMIN_CONFIGURATION";
export const PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RHADMIN_CONFIGURATION";
export const SUCCESS_PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RHADMIN_CONFIGURATION";
export const GET_OPENAI_CONFIGURATION =
  "rhadminConfiguration@GET_OPENAI_CONFIGURATION";
export const SET_OPENAI_CONFIGURATION =
  "rhadminConfiguration@SET_OPENAI_CONFIGURATION";
export const PERSIST_OPENAI_CONFIGURATION =
  "rhadminConfiguration@PERSIST_OPENAI_CONFIGURATION";
export const SUCCESS_PERSIST_OPENAI_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_OPENAI_CONFIGURATION";
  export const CLEAR_OPENAI_CONFIGURATION =
  "rhadminConfiguration@CLEAR_OPENAI_CONFIGURATION";
export const DELETE_OPENAI_CONFIGURATION =
  "rhadminConfiguration@DELETE_OPENAI_CONFIGURATION";
  export const SUCCESS_DELETE_OPENAI_DOCUMENT =
  "rhadminConfiguration@SUCCESS_DELETE_OPENAI_DOCUMENT";

export const getRHAdminConfiguration = (organizationId) => {
  return { type: GET_RHADMIN_CONFIGURATION, organizationId };
};

export const setRHAdminConfiguration = (configuration) => {
  return { type: SET_RHADMIN_CONFIGURATION, configuration };
};

export const clearRHAdminConfiguration = () => {
  return { type: CLEAR_RHADMIN_CONFIGURATION };
};

export const clearOpenAiConfiguration = () => {
  return { type: CLEAR_OPENAI_CONFIGURATION };
};

export const persistRHAdminConfiguration = (configuration) => {
  return { type: PERSIST_RHADMIN_CONFIGURATION, configuration };
};

export const sucessesPersistRHAdminConfiguration = () => {
  return { type: SUCCESS_PERSIST_RHADMIN_CONFIGURATION };
};

export const getOpenAIConfiguration = (organizationId) => {
  return { type: GET_OPENAI_CONFIGURATION, organizationId };
};

export const setOpenAiConfiguration = (configuration) => {
  return { type: SET_OPENAI_CONFIGURATION, configuration };
};

export const persistOpenAIConfiguration = (configuration) => {
  return { type: PERSIST_OPENAI_CONFIGURATION, configuration };
};
export const deleteOpenAiConfiguration = (id) => {
  return { type: DELETE_OPENAI_CONFIGURATION, id };
};

export const sucessesPersisOpenAIConfiguration = () => {
  return { type: SUCCESS_PERSIST_OPENAI_CONFIGURATION };
};

export const successDeleteOpenaiDocument = () => {
  return { type: SUCCESS_DELETE_OPENAI_DOCUMENT };
}
//BOLSA DE HORAS

export const GET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_CONFIGURATION";
export const SET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_CONFIGURATION";
export const CLEAR_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_CONFIGURATION";
export const PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const GET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_USERS_GROUP";
export const CLEAR_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_USERS_GROUP";
export const SET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_USERS_GROUP";
export const GET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_BOLSA_HORAS";
export const SET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_BOLSA_HORAS";
export const CLEAR_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_BOLSA_HORAS";
export const GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
export const SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
export const CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const GET_RHCURRENCY_CONFIGURATION = "rhadminConfiguration@GET_RHCURRENCY_CONFIGURATION";
  export const PERSIST_RHCURRENCY_CONFIGURATION = "rhadminConfiguration@PERSIST_RHCURRENCY_CONFIGURATION";
  export const CLEAR_RHCURRENCY_CONFIGURATION = "rhadminConfiguration@CLEAR_RHCURRENCY_CONFIGURATION";
  export const SET_RHCURRENCY_CONFIGURATION = "rhadminConfiguration@SET_RHCURRENCY_CONFIGURATION";
  export const SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION = "rhadminConfiguration@SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION";

export const getRHCurrencyConfiguration = (organizationId) => {
  return { type: GET_RHCURRENCY_CONFIGURATION, organizationId };
}

export const persistRHCurrencyConfiguration = (configuration) => {
  return { type: PERSIST_RHCURRENCY_CONFIGURATION, configuration };
}
export const clearRHCurrencyConfiguration = () => {
  return { type: CLEAR_RHCURRENCY_CONFIGURATION };
}
export const setRHCurrencyConfiguration = (configuration) => {
  return { type: SET_RHCURRENCY_CONFIGURATION, configuration };
}

export const sucessesPersistRHCurrencyConfiguration = () => {
  return { type: SUCCESS_PERSIST_RHCURRENCY_CONFIGURATION };
}

export const getRHBolsaHorasConfiguration = (organizationId) => {
  return { type: GET_RH_BOLSA_HORAS_CONFIGURATION, organizationId };
};

export const setRHBolsaHorasConfiguration = (configuration) => {
  return { type: SET_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const clearRHBolsaHorasConfiguration = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_CONFIGURATION };
};
export const getRHBolsaHorasUsersGroup = () => {
  return { type: GET_RH_BOLSA_HORAS_USERS_GROUP };
};

export const clearRHBolsaHorasUsersGroup = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_USERS_GROUP };
};

export const setRHBolsaHorasUsersGroup = (usersGroup) => {
  return { type: SET_RH_BOLSA_HORAS_USERS_GROUP, usersGroup };
};

export const persistRHBolsaHorasConfiguration = (configuration) => {
  return { type: PERSIST_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const sucessesPersistRHBolsaHorasConfiguration = () => {
  return { type: SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION };
};

export const getRHSaldoBolsaHoras = (usrId) => {
  return { type: GET_RH_SALDO_BOLSA_HORAS, usrId };
};

export const setRHSaldoBolsaHoras = (listSaldo) => {
  return { type: SET_RH_SALDO_BOLSA_HORAS, listSaldo };
};

export const clearRHSaldoBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_BOLSA_HORAS };
};
export const getRHSaldoInicialFinalBolsaHoras = (usrId, data) => {
  return { type: GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS, usrId, data };
};

export const setRHSaldoInicialFinalBolsaHoras = (listSaldoInicialFinal) => {
  return {
    type: SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
    listSaldoInicialFinal,
  };
};

export const clearRHSaldoInicialFinalBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS };
};
