import React, { useCallback, useEffect, useState, useRef } from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import "../../RhCollaborator/RhCollaborator.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getCollaborators,
  getTrabalhoRemoto,
  getAllTrabalhoRemoto,
  postTrabalhoRemoto,
  postTrabalhoRemotoConfirm,
  getInfoSaldoTrabalhoRemoto,
  getExportVals,
  postRemoveDaysTrabalhoRemoto,
} from "~/store/ducks/portalrh/actionTypes";
import { updateUserSettings } from "~/pages/User/actions";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import Sidebar from "../../RhCollaborator/Sidebar";
import InputLabel from "@mui/material/InputLabel";
import { v4 as uuidv4 } from "uuid";
import { withNamespaces } from "react-i18next";
import DatePicker from "~/components/DatePicker";
import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import "~/assets/css/scheduler.css";
import Dropdown from "~/components/Dropdown";
import { Dropdown as DropDownBoot } from "react-bootstrap";
import moment from "moment";
import {
  colors,
  iflowConstants,
  variablesEvents,
  navigate,
} from "./ColorVariables";
import Spinner from "~/components/Spinner";
import { Button, Stack, Tabs, Tab } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TextField } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import oauth from "~/utils/oauth";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "@mui/material/Badge";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  FaCalendarDay,
  FaCalendarAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaUmbrellaBeach,
  FaBookmark,
  FaListUl,
  FaTrash,
  FaClock,
  FaCheckCircle,
  FaMinusCircle,
  FaTimesCircle,
  FaEye,
  FaCalendarPlus,
  FaCircle,
  FaPlus,
  FaFileExport,
} from "react-icons/fa";
import { GoPlusCircle, GoTrash } from "react-icons/go";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import Toast from "react-bootstrap/Toast";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FlowModal from "~/components/FlowModal";
import {
  setErrorPostTrabalhoRemoto,
  setErrorPostTrabalhoRemotoConfirm,
  setRemoveDaysTrabalhoRemoto,
  setExportVals,
} from "~/store/ducks/portalrh/actionTypes";

import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { getRHBolsaHorasConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import Year from "./Year";

import MenuItemWithHover from "./MenuItemWithHover";

import uuid from "uuid/v1";

import { Popover } from "@material-ui/core";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { Modal as ModalMaterial } from "@mui/material";
import Box from "@mui/material/Box";
import { BsXLg } from "react-icons/bs";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  border: "1px solid rgba(0,0,0,.2)",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const devLogConsole = require("~/utils/devLog");
const SchedulerComponent = ({
  portalRhMenus,
  designacao,
  path,
  t,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  setLoadEventsStart,
  selectedUser,
  setSelectedUser,
  grupos,
  setgrupos,
  activeButton,
  setActiveButton,
  hideChangeView,
  infoDataWorkHour,
  dataSelectedRequest,
  showAllGroups = false,
  exportBtn,
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { iflowpid } = useSelector((state) => state.processesReducer);
  const { rhbolsaHorasConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const {
    collaborators,
    trabalhoRemoto,
    isLoadingTrabalhoRemoto,
    trabalhoRemotomsg,
    trabalhoRemotomsgConfirm,
    gruposTrabalhoRemoto,
    trabalhoRemotoInfoSaldo,
    exportMsg,
    isLoadingExport,
    removeDaysTrabalhoRemotoMSG,
  } = useSelector((state) => state.portalrhReducer);
  const { rhbolsaHorasUsersGroup } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const { organization } = useSelector((state) => state.organizationsReducer);

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(userSettings.language);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [events, setEvents] = useState([]);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [dateSelected, setDateSelected] = useState(new Date());
  const [loadNewDateSelected, setLoadNewDateSelected] = useState(new Date());
  const [workdays_hours, setworkdays_hours] = useState({});
  const [defaultView, setDefaultView] = useState("month");
  const [showToast, setShowToast] = useState(false);
  const [showToastMSG, setShowToastMSG] = useState(false);
  const [toastText, settoastText] = useState("");
  const [showModalWithDaysMarked, setShowModalWithDaysMarked] = useState(false);
  const [showModalWithDaysMarkedConfirm, setShowModalWithDaysMarkedConfirm] =
    useState(false);

  const [openExport, setOpenExport] = useState(false);
  const [messageTranslated, setmessageTranslated] = useState("");
  const [openExportError, setOpenExportError] = useState(false);
  const [rangesState, setrangesState] = useState({});

  const [selectedGroup, setSelectedGroup] = useState("");
  const [criteriaGroup, setCriteriaGroup] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersColor, setSelectedUsersColor] = useState({});
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectAll, setSelectAll] = useState(showAllGroups);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popupEvent, setPopupEvent] = useState(null);

  const [showModalCheckDays, setShowModalCheckDays] = useState(false);
  const [showModalCriteria, setShowModalCriteria] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [deleteDays, setDeleteDays] = useState([]);
  const [eventDaysForModal, setEventDaysForModal] = useState([]);

  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");
  const [starEndtMonth, setstarEndtMonth] = useState({
    start: "",
    end: "",
  });
  const [comentario, setComentario] = useState("");
  const [textoContador, setTextContador] = useState("");
  const [textoContadorAdicional, setTextContadorAdicional] = useState("");
  const [pinnedItem, setPinnedItem] = useState(null);

  const open = Boolean(anchorEl);

  const [key, setKey] = useState(designacao);

  const dispatch = useDispatch();

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });
  useEffect(() => {
    if (Object.keys(userSettings).length > 0 && pinnedItem === null) {
      const remoteWorkPinnedGroup =
        userSettings.remoteWorkPinnedGroup &&
        userSettings.remoteWorkPinnedGroup.map((item) => parseInt(item, 10));
      setPinnedItem(remoteWorkPinnedGroup || []);
    }
  }, [userSettings]);

  useEffect(() => {
    setShowToastMSG(false);
    setOpenExport(false);
    setmessageTranslated("");
    setOpenExportError(false);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
    const { start, end } = getMonthRange(dateSelected);
    rangeChange({ start, end });
    setstarEndtMonth({
      start: moment(dateSelected).startOf("month").startOf("week"),
      end: moment(dateSelected).endOf("month").endOf("week"),
    });
  }, []);

  useEffect(() => {
    if (Object.keys(trabalhoRemotoInfoSaldo).length > 0) {
      setTextContador(trabalhoRemotoInfoSaldo.situacao);
      setTextContadorAdicional(trabalhoRemotoInfoSaldo.bolsa);
    } else {
      setTextContador("");
      setTextContadorAdicional("");
    }
  }, [trabalhoRemotoInfoSaldo]);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(userSettings).length > 0 && pinnedItem !== null) {
      const payload = {
        ...userSettings,
        remoteWorkPinnedGroup: pinnedItem,
      };

      dispatch(updateUserSettings(payload));
    }
  }, [pinnedItem]);

  useEffect(() => {
    if (exportMsg !== "") {
      let messagetrans;
      if (exportMsg === "exportedScreen") {
        messagetrans = t("portalrh.workDays.export.exportedScreen");
      } else if (exportMsg === "exportedCriteria") {
        messagetrans = t("portalrh.workDays.export.exportedCriteria");
      }
      if (exportMsg.includes("exportedYear")) {
        let splited = exportMsg.split("::");
        messagetrans =
          t(`portalrh.workDays.export.${splited[0]}`) + "_" + splited[1];
      }
      setmessageTranslated(messagetrans);
      setOpenExport(true);
    }
  }, [exportMsg]);

  useEffect(() => {
    if (
      Object.keys(gruposTrabalhoRemoto).length > 0 &&
      infoDataWorkHour !== undefined
    ) {
      for (const grupo of gruposTrabalhoRemoto) {
        for (const usuario of grupo.userList) {
          if (usuario.id === infoDataWorkHour) {
            setSelectedGroup(grupo.grupo.id);
            const usersInGroup = grupo.userList.map((user) => user.id);
            setSelectedUsers(usersInGroup);
            break;
          }
        }
      }
    } else {
      if (Object.keys(gruposTrabalhoRemoto).length > 0) {
        if (selectedGroup === "") {
          const allUsers = gruposTrabalhoRemoto.flatMap(
            (group) => group.userList
          );
          setDisplayedUsers(allUsers);
          // const usersInGroup = allUsers.map((user) => user.id);
          // setSelectedUsers(usersInGroup);
          // setSelectAll(true);
        }
      }
    }

    if (dataSelectedRequest != undefined)
      getDatesFromDays(
        dataSelectedRequest.length > 0
          ? new Date(dataSelectedRequest[0].data)
          : new Date()
      );
  }, [gruposTrabalhoRemoto, infoDataWorkHour]);

  useEffect(() => {
    if (userNamePortalRH != undefined) {
      callUpdateTrabalhoRemoto();
    }
  }, [loadNewDateSelected, userNamePortalRH]);

  const callUpdateTrabalhoRemoto = () => {
    if (isEditable) {
      const payload = {
        usrId: userNamePortalRH.id,
        entidade: userNamePortalRH.codEntidade,
        numFunc: userNamePortalRH.funcionarioNum,
        ano: loadNewDateSelected.getFullYear(),
      };
      dispatch(getTrabalhoRemoto(payload));
      const payloadInfo = {
        usrId: userNamePortalRH.id,
        ano: loadNewDateSelected.getFullYear(),
        mes: dateSelected.getMonth() + 1,
      };
      dispatch(getInfoSaldoTrabalhoRemoto(payloadInfo));
    } else {
      const selectedGroupInfo =
        Object.keys(gruposTrabalhoRemoto).length > 0 &&
        gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup);
      if (selectedGroupInfo) {
        const userIds = selectedGroupInfo.userList.map((user) => user.id);
        const payload = {
          ids: userIds,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllTrabalhoRemoto(payload));
      } else {
        const userIds = displayedUsers.map((user) => user.id);
        const payload = {
          ids: userIds,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllTrabalhoRemoto(payload));
      }
    }
  };

  useEffect(() => {
    setForceUpdate((prevKey) => prevKey + 1);
    if (Object.keys(trabalhoRemoto).length > 0) {
      setworkdays_hours(trabalhoRemoto);
    } else {
      setworkdays_hours([]);
    }
  }, [trabalhoRemoto]);

  useEffect(() => {
    // if (Object.keys(workdays_hours).length > 0) {
    const supportedLanguages = ["es", "pt"];
    if (userSettings != undefined) {
      if (supportedLanguages.includes(selectedLocale)) {
        require(`moment/locale/${selectedLocale}.js`);
        moment.locale(selectedLocale);
        moment.updateLocale(selectedLocale, {
          week: {
            dow: 0,
          },
        });
        setLocalizer(momentLocalizer(moment));
      } else {
        moment.locale("en");
        setLocalizer(momentLocalizer(moment));
      }
      setSelectedLocale(userSettings.language);
      //if (userNamePortalRH != undefined) getDatesFromDays(dateSelected);
    }
    // }
  }, [userSettings.language, selectedLocale, workdays_hours]);

  useEffect(() => {
    if (Object.keys(trabalhoRemotomsg).length > 0) {
      setShowModalWithDaysMarkedConfirm(true);
      setShowModalWithDaysMarked(false);
      //callUpdateTrabalhoRemoto();
    } else {
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
    }
  }, [trabalhoRemotomsg]);

  useEffect(() => {
    if (trabalhoRemotomsgConfirm != "") {
      setShowToastMSG(true);
      settoastText(trabalhoRemotomsgConfirm);
      callUpdateTrabalhoRemoto();
    } else {
      setShowToastMSG(false);
      settoastText("");
    }
  }, [trabalhoRemotomsgConfirm]);

  useEffect(() => {
    devLogConsole(removeDaysTrabalhoRemotoMSG);
    if (removeDaysTrabalhoRemotoMSG !== "") {
      setShowToastMSG(true);
      settoastText(removeDaysTrabalhoRemotoMSG);
    } else {
      setShowToastMSG(false);
      settoastText("");
    }
  }, [removeDaysTrabalhoRemotoMSG]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
      settoastText("");
    }, 5000);
  }, [showToast]);

  useEffect(() => {
    setTimeout(() => {
      setShowToastMSG(false);
      settoastText("");
      dispatch(setErrorPostTrabalhoRemotoConfirm(""));
      dispatch(setRemoveDaysTrabalhoRemoto(""));
    }, 5000);
  }, [showToastMSG]);

  localizer.formats.yearHeaderFormat = "YYYY";

  function convertToEvents(data, type, color) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: item.obs,
        start: startLocal,
        end: endLocal,
        allDay: "true",
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsFolgas(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.folgas"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsDiasMarcados(data, type, color) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title:
          item.qtDias === 0
            ? t("portalrh.workDays.diasMarcadosMeiodia")
            : t("portalrh.workDays.diasMarcados"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        state: item.aprovado,
        idObj: item.id,
        flag: item.flag,
      };
    });
  }

  function convertToEventsDiasMarcadosTeam(data, type) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      const color = item.userId === infoDataWorkHour ? "#44B89B" : item.color;

      return {
        id: uuidv4(),
        title: item.username,
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        state: item.aprovado,
      };
    });
  }

  function convertToEventsMarkedDays(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.selectedDay"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        checked: true,
        checkedHalfDay: false,
      };
    });
  }

  useEffect(() => {
    if (Object.keys(workdays_hours).length > 0) {
      // if (!isEditable) {
      //   const diasMarcadosTeam = convertToEventsDiasMarcadosTeam(
      //     workdays_hours?.diasmarcadosTeam,
      //     "diasMarcados",
      //     "#4447A4"
      //   );
      //   setEvents([...diasMarcadosTeam]);
      // } else {
      const feriados = convertToEvents(
        workdays_hours?.feriados,
        "feriados",
        "#D6D6D6"
      );
      const folgas = convertToEventsFolgas(
        workdays_hours?.folgas,
        "folgas",
        "#D6D6D6"
      );
      const diasMarcados = convertToEventsDiasMarcados(
        workdays_hours?.diasmarcados,
        "diasMarcados",
        "#4447A4"
      );
      setEvents([...feriados, ...folgas, ...diasMarcados]);
      //}
    } else {
      setEvents([]);
    }
  }, [workdays_hours]);

  const onDrillDown = (slotInfo) => {
    if (isEditable) {
      if (slotInfo.getMonth() !== dateSelected.getMonth()) {
        return;
      }

      const eventOnThisDay = events.find((event) =>
        moment(event.start).isSame(slotInfo, "day")
      );

      if (eventOnThisDay) {
        if (
          eventOnThisDay.type !== "diasMarcados" &&
          eventOnThisDay.type !== "daysmarked"
        ) {
          return;
        } else if (eventOnThisDay.type === "diasMarcados") {
          if (eventOnThisDay.flag === 1) confirmDeleteDay(slotInfo);
          else {
            return;
          }
        } else {
          setSelectedSlot(slotInfo);
          confirmCheckDay(slotInfo);
        }
      } else {
        setSelectedSlot(slotInfo);
        confirmCheckDay(slotInfo);
      }
    }
  };

  const handleModalCloseCheckDays = () => {
    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };

  const handleModalCloseCriteria = () => {
    setShowModalCriteria(false);
    setCriteriaGroup("");
    setEndAt("");
    setStartAt("");
  };

  const handleEventAddition = (eventType) => {
    if (eventType === "normal") {
      confirmCheckDay(selectedSlot);
    } else if (eventType === "custom") {
      confirmCheckDay(selectedSlot);
    }
    {
      return;
    }

    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };

  const confirmDeleteDay = (slotInfo) => {
    const slotInfoDate = moment(slotInfo).format("YYYY-MM-DD");

    const matchingEvents = events.filter(
      (event) =>
        event.type === "diasMarcados" &&
        moment(event.start).format("YYYY-MM-DD") === slotInfoDate
    );

    matchingEvents.forEach((event) => {
      const eventId = event.idObj; // Use idObj as the unique identifier

      if (deleteDays.some((e) => e.idObj === eventId)) {
        setDeleteDays((prevEvents) =>
          prevEvents.filter((e) => e.idObj !== eventId)
        );
      } else {
        setDeleteDays((prevEvents) => [...prevEvents, event]);
      }
    });
  };

  const confirmCheckDay = (slotInfo) => {
    const daysMarked = convertToEventsMarkedDays(
      [slotInfo],
      "daysmarked",
      "#44A49B"
    );

    const isEventAlreadyAdded = events.some(
      (event) =>
        event.type === "daysmarked" &&
        moment(event.start).isSame(daysMarked[0].start, "day")
    );

    if (isEventAlreadyAdded) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) =>
            !(
              event.type === "daysmarked" &&
              moment(event.start).isSame(daysMarked[0].start, "day")
            )
        )
      );
    } else {
      setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
    }
  };

  const getDatesFromDays = (date) => {
    setTextContador("");
    setTextContadorAdicional("");
    const currentDate = date;
    setDateSelected(currentDate);
    const dataObj = new Date(date);
    const payloadInfo = {
      usrId: userNamePortalRH.id,
      ano: loadNewDateSelected.getFullYear(),
      mes: dataObj.getMonth() + 1,
    };
    dispatch(getInfoSaldoTrabalhoRemoto(payloadInfo));
    setstarEndtMonth({
      start: moment(date).startOf("month").startOf("week"),
      end: moment(date).endOf("month").endOf("week"),
    });
    const { start, end } = getMonthRange(currentDate);
    rangeChange({ start, end });
    if (currentDate.getFullYear() !== loadNewDateSelected.getFullYear()) {
      setLoadNewDateSelected(currentDate);
    }
  };

  const rangeChange = useCallback(
    (range) => {
      setrangesState(range);
      return {};
    },
    [events]
  );

  const getMonthRange = (selectedDate) => {
    const startOfMonth = moment(selectedDate).startOf("month").toDate();
    const endOfMonth = moment(selectedDate).endOf("month").toDate();

    return { start: startOfMonth, end: endOfMonth };
  };

  const dayPropGetter = useCallback(
    (date) => {
      const hasDaysMarkedEvent = events.some(
        (event) =>
          event.type != "daysmarked" &&
          event.type != "diasMarcados" &&
          moment(event.start).isSame(date, "day")
      );
      const hasDiasMarcadosEvent = events.some(
        (event) =>
          event.type != "diasMarcados" &&
          event.type != "daysmarked" &&
          moment(event.start).isSame(date, "day")
      );
      if (dataSelectedRequest != undefined) {
        const isDateSelected = dataSelectedRequest.some((selectedDate) =>
          moment(selectedDate.data).isSame(date, "day")
        );

        if (isDateSelected) {
          return {
            className: "daytoConfirm",
            // // style: {
            // //   backgroundColor: "darkslategray",
            // // },
          };
        }
      }
      // if (dataSelectedRequest && moment(dataSelectedRequest).isSame(date, "day")) {
      //   return {
      //     style: {
      //       backgroundColor: "rgba(10, 100, 94, 0.298)"
      //     },
      //   };
      // }

      if (hasDaysMarkedEvent) {
        return {
          style: {
            backgroundColor: "#D6D6D6",
            color: "#666",
            pointerEvents: "none",
          },
        };
      }
      if (hasDiasMarcadosEvent) {
        return {
          style: {
            backgroundColor: "#F7F7F7",
            color: "#666",
            pointerEvents: "none",
          },
        };
      }

      return {};
    },
    [events]
  );

  const handleGroupChange = (event) => {
    //setSelectAll(true);
    if (event.target.value) {
      setEvents([]);
      setForceUpdate((prevKey) => prevKey + 1);
      setworkdays_hours([]);
    }

    setSelectedGroup(event.target.value);
    if (event.target.value === "") {
      const allUsers = gruposTrabalhoRemoto.flatMap((group) => group.userList);
      setDisplayedUsers(allUsers);
      // const usersInGroup = allUsers.map((user) => user.id);
      // setSelectedUsers(usersInGroup);
    } else {
      const selectedGroup = gruposTrabalhoRemoto.find(
        (group) => group.grupo.id === event.target.value
      );
      setDisplayedUsers(selectedGroup ? selectedGroup.userList : []);
      // const usersInGroup = selectedGroup.userList.map((user) => user.id);
      // setSelectedUsers(usersInGroup);
    }

    setSelectedTab(0);
    //handleUserChange("selectAll", false)
  };
  const handleUserChange = (userId, vaaa) => {
    if (userId === "selectAll") {
      const allUserIds = displayedUsersList.map((user) => user.id);
      setSelectedUsers(selectAll ? [] : allUserIds);
      setSelectAll(!selectAll);
    } else {
      const updatedSelectedUsers = selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId];

      setSelectedUsers(updatedSelectedUsers);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    if (!isEditable) {
      if (selectedUsers.length > 0) {
        filterWorkdaysByUsers();
      } else {
        setEvents([]);
      }
    }
  }, [selectedUsers, workdays_hours]);

  useEffect(() => {
    if (selectedGroup != "") {
      const selectedGroupInfo =
        Object.keys(gruposTrabalhoRemoto).length > 0 &&
        gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup);

      if (selectedGroupInfo) {
        const userIds = selectedGroupInfo.userList.map((user) => user.id);
        const payload = {
          ids: userIds,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllTrabalhoRemoto(payload));
      }
    } else {
      const userIds = displayedUsers.map((user) => user.id);
      const payload = {
        ids: userIds,
        ano: loadNewDateSelected.getFullYear(),
      };
      dispatch(getAllTrabalhoRemoto(payload));
    }
  }, [selectedGroup, displayedUsers]);

  const filterWorkdaysByUsers = () => {
    if (workdays_hours?.diasmarcadosTeam) {
      const filteredDiasMarcadosTeam = workdays_hours.diasmarcadosTeam.filter(
        (diasMarcados) => selectedUsers.includes(diasMarcados.userId)
      );

      // Atualize o estado selectedUsersColor com as cores associadas a cada usuário
      const updatedSelectedUsersColor = { ...selectedUsersColor };

      filteredDiasMarcadosTeam.forEach((diasMarcados) => {
        const { userId, color } = diasMarcados;
        updatedSelectedUsersColor[userId] = color;
      });

      setSelectedUsersColor(updatedSelectedUsersColor);
      const diasMarcadosTeamEvents = convertToEventsDiasMarcadosTeam(
        filteredDiasMarcadosTeam,
        "diasMarcados"
      );
      setEvents([...diasMarcadosTeamEvents]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
    setSelectedTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const allUsers =
    (Object.keys(gruposTrabalhoRemoto).length > 0 &&
      gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup)
        ?.userList) ||
    displayedUsers;

  const filteredUsers = allUsers.filter((user) =>
    user.nomeCurto.toLowerCase().includes(searchFilter.toLowerCase())
  );

  const displayedUsersList =
    selectedTab === 0
      ? filteredUsers
      : selectedGroup === ""
      ? allUsers
      : allUsers.filter((user) => selectedUsers.includes(user.id));

  const handlePinUnpin = (id) => {
    setPinnedItem((prevPinnedItem) => {
      if (prevPinnedItem.includes(id)) {
        // If the id is already in pinnedItem, remove it
        return prevPinnedItem.filter((itemId) => itemId !== id);
      } else {
        // Otherwise, add the id to pinnedItem
        return [...prevPinnedItem, id];
      }
    });
  };

  const listUsersByGroupConstructor = () => {
    return (
      <div>
        <FormControl>
          <Select
            value={selectedGroup}
            label={t("portalrh.workDays.groups")}
            onChange={handleGroupChange}
            displayEmpty
            inputProps={{ "aria-label": "Select group" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                  overflowY: "auto",
                  minWidth: "450px",
                },
                className: "scrollBar-visible-year",
              },
            }}
          >
            <MenuItem value="">{t("portalrh.workDays.all")}</MenuItem>
            {gruposTrabalhoRemoto
              .sort((a, b) => {
                const aIsPinned = (pinnedItem || []).includes(a.grupo.id);
                const bIsPinned = (pinnedItem || []).includes(b.grupo.id);
                if (aIsPinned && !bIsPinned) {
                  return -1;
                }
                if (!aIsPinned && bIsPinned) {
                  return 1;
                }
                return 0;
              })
              .map((group) => (
                <MenuItemWithHover
                  key={group.grupo.id}
                  groupID={group.grupo.id}
                  groupDesignacao={group.grupo.designacao}
                  handlePinUnpin={handlePinUnpin}
                  alreadyPinned={pinnedItem || []}
                />
              ))}
          </Select>

          <>
            {selectedGroup !== "" && (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#4484f4",
                    color: "#4484f4",
                    borderRadius: "0px 0px 8px 0px",
                  },
                }}
              >
                <Tab label={t("portalrh.workDays.all")} />
                <Tab label={t("portalrh.workDays.selected")} />
              </Tabs>
            )}

            {selectedTab == 0 && (
              <>
                <TextField
                  label={t("portalrh.workDays.searchCol")}
                  variant="standard"
                  value={searchFilter}
                  onChange={handleSearchChange}
                  size="small"
                  style={{ margin: "10px 0px" }}
                />
                {searchFilter === "" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={() => handleUserChange("selectAll")}
                      />
                    }
                    label={t("portalrh.workDays.selectAll")}
                  />
                )}
              </>
            )}
          </>
        </FormControl>
        <div
          style={{
            overflowY: "auto",
            maxHeight: hideChangeView ? "480px" : "500px",
            margin: selectedTab == 0 ? "0px 10px 10px" : "10px 10px 10px",
            height: hideChangeView ? "480px" : "500px",
          }}
          className="scrollBar-visible-year"
        >
          <FormGroup>
            {displayedUsersList.map((user) => (
              <FormControlLabel
                key={uuidv4()}
                control={
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleUserChange(user.id)}
                    style={{
                      color: selectedUsers.includes(user.id)
                        ? selectedUsersColor[user.id]
                        : "inherit",
                    }}
                  />
                }
                label={user.nomeCurto}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    );
  };

  const schedulerContrutor = () => {
    return (
      <div key={forceUpdate} style={{ width: "100%" }}>
        <>
          {isEditable ? (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              t={t}
              views={{
                day: false,
                week: false,
                month: true,
                year: Year,
              }}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={true}
              popup
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              onRangeChange={rangeChange}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              tooltipAccessor={constructTooltip}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";

                if (myEventsList.type === "diasMarcados") {
                  return {
                    className:
                      myEventsList.type === "diasMarcados"
                        ? "diasMarcados"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                if (myEventsList.type === "daysmarked") {
                  return {
                    className:
                      myEventsList.type === "daysmarked" ? "daysmarked" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          ) : (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              t={t}
              views={{
                day: false,
                week: false,
                month: true,
                year: Year,
              }}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={false}
              popup={true}
              //showAllEvents={true}
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              tooltipAccessor={constructTooltip}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";
                if (myEventsList.type === "diasMarcados") {
                  const eventos = eventosDoDia(myEventsList.start);
                  if (eventos.length > 2) {
                    return {
                      className:
                        myEventsList.type === "diasMarcados"
                          ? "diasMarcados"
                          : "",
                      style: {
                        border: "0px",
                        cursor: "default",
                        backgroundColor: "transparent",
                      },
                    };
                  }
                  return {
                    className:
                      myEventsList.type === "diasMarcados"
                        ? "diasMarcados"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                if (myEventsList.type === "daysmarked") {
                  return {
                    className:
                      myEventsList.type === "daysmarked" ? "daysmarked" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          )}
          <Popover
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl}
          >
            {Array.isArray(popupEvent) &&
              popupEvent.map((event) => (
                <div
                  key={event.id}
                  style={{ padding: "10px", cursor: "default" }}
                >
                  <CreatePopUpEntry event={event} />
                </div>
              ))}
            <Stack
              direction="row"
              style={{ placeContent: "center" }}
              alignItems="center"
              spacing={2}
              margin="5px"
            >
              <ButtonBoot className="card-button-red" onClick={handleClose}>
                {t("portalrh.workDays.close")}
              </ButtonBoot>
            </Stack>
          </Popover>

          {dataSelectedRequest === undefined && (
            <div id="legenda" style={{ padding: "0px", textAlignLast: "end", marginTop: "1rem"  }}>
              {isEditable && (
                <Button
                  size="small"
                  variant="text"
                  style={{
                    pointerEvents: "none",
                    padding: "0px 10px 0px 0px",
                    color: "#4C4B4B",
                  }}
                >
                  <FaCircle
                    color="rgb(171, 171, 171)"
                    style={{ margin: "0px 5px" }}
                  />
                  {t("portalrh.workDays.legend")}
                </Button>
              )}
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaClock color="goldenrod" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Pendente")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaCheckCircle color="green" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Aprovado")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaTimesCircle color="red" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.refused")}
              </Button>
            </div>
          )}
        </>
      </div>
    );
  };

  const CreatePopUpEntry = ({ event }) => {
    const border = event.color ? `2px solid ${event.color}` : "2px solid blue";
    const opacity = 0.3;
    const backgroundColor = event.color
      ? `${event.color}${Math.floor(opacity * 255).toString(16)}`
      : "blue";

    const style = {
      color: event.color,
      background: backgroundColor,
      border: border,
    };

    return (
      <p className="popupP" style={style}>
        {createEventImageDependingState(event.state)}
        {event.title}
      </p>
    );
  };

  const constructTooltip = (event) => {
    return (
      event.title + " - " + createEventDescriptionDependingState(event.state)
    );
  };

  function CustomDateHeader({ label, drilldownView, onDrillDown, isOffRange }) {
    if (isEditable) {
      const filteredEvents = events.filter(
        (event) => event.start.getMonth() === dateSelected.getMonth()
      );
      const padNumber = (number) => (number < 10 ? `0${number}` : number);

      const hasEvents = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());

        return event.type !== "daysmarked" && eventDayString === label;
      });

      const hasEventsMark = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());
        return event.type === "daysmarked" && eventDayString === label;
      });

      const hasEventsMarkDiasMarcados = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());
        return (
          event.type === "diasMarcados" &&
          eventDayString === label &&
          event.flag === 1
        );
      });

      const hasDeleteEvent = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());

        return deleteDays.some(
          (deleteEvent) =>
            moment(deleteEvent.start).format("YYYY-MM-DD") ===
              moment(event.start).format("YYYY-MM-DD") &&
            eventDayString === label
        );
      });

      return (
        <div onClick={onDrillDown} className="custom-date-header">
          <div className="dh-item header-left">
            {!isOffRange && !hasEvents ? (
              hasEventsMark ? (
                <MdOutlineRemoveCircleOutline color="#b1b1b1" />
              ) : (
                <GoPlusCircle color="#b1b1b1" />
              )
            ) : null}
            {!isOffRange && hasEventsMarkDiasMarcados ? (
              hasDeleteEvent ? (
                <Badge color="error" size="large" variant="dot">
                  <GoTrash color="#b1b1b1" />
                </Badge>
              ) : (
                <GoTrash color="#b1b1b1" />
              )
            ) : null}
          </div>
          <div className="dh-item header-right">
            <a>{label}</a>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={onDrillDown} className={`custom-date-header`}>
          <div className="dh-item header-right">
            <a>{label}</a>
          </div>
        </div>
      );
    }
  }

  const handleClick = (event, selectedEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.top - rect.height;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: rect.left });
    setPopupEvent(selectedEvent);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopupEvent(null);

    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  function Event({ event }) {
    if (event.type == "daysmarked") {
      return (
        <div>
          <FaBookmark
            style={{
              fontSize: "small",
              alignSelf: "center",
              marginRight: "5px",
              color: event.color,
            }}
          />
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "ferias") {
      return null;
    }
    if (event.type == "diasMarcados") {
      const eventos = eventosDoDia(event.start);
      if (eventos.length > 2) {
        return (
          <div
            onClick={(e) => handleClick(e, eventos)}
            style={{ textAlign: "center" }}
            title={`${eventos.length} ${t("portalrh.workDays.eventos")}`}
          >
            <span
              className="eventSpan"
              style={{
                display: "inline-flex",
                marginTop: "2px",
                backgroundColor: "rgba(51,5,141, 0.4)",
                padding: "5px",
                width: "40px",
                borderRadius: "20px",
                fontSize: "small",
                border: "2px solid rgb(51,5,141)",
                color: "rgb(51,5,141)",
              }}
            >
              + {eventos.length}
            </span>
          </div>
        );
      }

      return (
        <div>
          {createEventImageDependingState(event.state)}
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    // if (event.type == "diasMarcados") {
    //   return (
    //     <div>
    //       {createEventImageDependingState(event.state)}
    //       <span
    //         className="eventSpan"
    //         style={{ display: "inline-flex", marginTop: "2px" }}
    //       >
    //         {event.title}
    //       </span>
    //     </div>
    //   );
    // }

    return null;
  }

  const eventosDoDia = (date) =>
    events.filter(
      (evento) =>
        evento.start.getDate() === date.getDate() &&
        evento.start.getMonth() === date.getMonth() &&
        evento.start.getFullYear() === date.getFullYear()
    );
  const createEventImageDependingState = (state) => {
    if (state === 0) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "goldenrod",
          }}
        />
      );
    }
    if (state === 1) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "goldenrod",
          }}
        />
      );
    }
    if (state === 2) {
      return (
        <FaMinusCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 3) {
      return (
        <FaCheckCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
    if (state === 4) {
      return (
        <FaTimesCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 5) {
      return (
        <FaEye
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
  };

  const createEventDescriptionDependingState = (state) => {
    if (state === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (state === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (state === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (state === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (state === 5) {
      return t("portalrh.workDays.Visto");
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[400]),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: grey[200],
    },
  }));

  const ColorButtonArrow = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[500],
    },
  }));

  const handleSendDays = () => {
    const eventsDays = events.filter((event) => event.type === "daysmarked");
    if (eventsDays.length <= 0) {
      return;
    }
    setEventDaysForModal(eventsDays);
    setShowModalWithDaysMarked(true);
  };

  const handleSaveWorkDays = () => {
    setEvents((prevEvents) => {
      const updatedEvents = prevEvents.map((prevEvent) => {
        const correspondingEvent = eventDaysForModal.find(
          (modalEvent) => modalEvent.id === prevEvent.id
        );

        return correspondingEvent || prevEvent;
      });

      return updatedEvents;
    });

    const dates = eventDaysForModal.map((event) => ({
      date: new Date(event.start).toISOString().split("T")[0],
      diaInteiro: event.checked,
    }));

    if (userNamePortalRH !== undefined) {
      const payload = {
        dates: dates,
        usrId: userNamePortalRH.id,
        username: userNamePortalRH.username,
      };
      dispatch(postTrabalhoRemoto(payload));
      setShowModalWithDaysMarked(false);
      setShowModalWithDaysMarkedConfirm(false);
    }
  };

  const handleSaveWorkDaysConfirm = () => {
    const eventsDays = events.filter((event) => event.type === "daysmarked");
    const dates = eventsDays.map((event) => ({
      date: new Date(event.start).toISOString().split("T")[0],
      diaInteiro: event.checked,
    }));

    if (userNamePortalRH != undefined) {
      const payload = {
        dates: dates,
        usrId: userNamePortalRH.id,
        username: userNamePortalRH.username,
        comentario: comentario,
      };
      dispatch(postTrabalhoRemotoConfirm(payload));
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
      setComentario("");
      setEventDaysForModal([]);
    }
  };

  const handleSendDeleteDays = () => {
    if (userNamePortalRH != undefined) {
      const ids = deleteDays.map((event) => event.idObj); // Extract idObj from each event in deleteDays

      const payload = {
        ids,
      };
      dispatch(postRemoveDaysTrabalhoRemoto(payload));
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
      setComentario("");
      setEventDaysForModal([]);
      setDeleteDays([]);
      callUpdateTrabalhoRemoto();
    }
  };

  const removeDayFromEvents = (day) => {
    const updatedEvents = events.filter((event) => {
      return !(event.type === day.type && event.id === day.id);
    });
    setEvents(updatedEvents);
  };

  const handleCheckboxChange = (day, type) => {
    setEventDaysForModal((prevEvents) => {
      const updatedEventsDays = prevEvents.map((eventDay) => {
        if (eventDay.id === day.id) {
          const newChecked = type === "diaInteiro" ? true : false;
          const newCheckedHalfDay = type === "halfDay" ? true : false;

          return {
            ...eventDay,
            checked: newChecked,
            checkedHalfDay: newCheckedHalfDay,
          };
        }

        return eventDay;
      });

      return updatedEventsDays;
    });
  };

  function ModalWithCheckDays() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCheckDays}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button"
              onClick={() => handleSaveWorkDays()}
            >
              {t("portalrh.workDays.submitDay")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalWithCriteria(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
        expport
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.export.title")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: "10px 0px" }}>
            <Col lg="12" style={{ padding: "0px" }}>
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.groups")}
              </InputLabel>
              <Select
                value={criteriaGroup}
                label="Grupos"
                onChange={(e) => setCriteriaGroup(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Select group" }}
              >
                <MenuItem value="">{t("portalrh.workDays.all")}</MenuItem>
                {Object.keys(gruposTrabalhoRemoto).length > 0 &&
                  gruposTrabalhoRemoto
                    .sort((a, b) => {
                      const aIsPinned = (pinnedItem || []).includes(a.grupo.id);
                      const bIsPinned = (pinnedItem || []).includes(b.grupo.id);
                      if (aIsPinned && !bIsPinned) {
                        return -1;
                      }
                      if (!aIsPinned && bIsPinned) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((group) => (
                      <MenuItemWithHover
                        key={group.grupo.id}
                        groupID={group.grupo.id}
                        groupDesignacao={group.grupo.designacao}
                        handlePinUnpin={handlePinUnpin}
                        alreadyPinned={pinnedItem}
                      />
                    ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.startDate")}
              </InputLabel>
              <DatePicker
                value={startAt}
                onChange={(e) => setStartAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.endDate")}
              </InputLabel>
              <DatePicker
                value={endAt}
                onChange={(e) => setEndAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCriteria}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button"
              onClick={() => handleSendCriteriaData()}
            >
              {t("portalrh.workDays.export.btn")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderListModals = (entry, add) =>
    entry.map((day) => (
      <ListGroup.Item
        key={day.date}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", marginBottom: "5px" }}>
          <FormControlLabel
            key={uuidv4()}
            style={{ margin: "0px" }}
            label={t("portalrh.workDays.diaInteiro")}
            control={
              <Checkbox
                style={{ margin: "0px" }}
                checked={
                  add ? day.checked : day.diaInteiro === "0" ? true : false
                }
                disabled={add ? false : true}
                onChange={() => handleCheckboxChange(day, "diaInteiro")}
              />
            }
          />
          <FormControlLabel
            key={uuidv4()}
            style={{ margin: "0px", marginLeft: "10px" }}
            label={t("portalrh.workDays.diasMarcadosMeiodia")}
            control={
              <Checkbox
                style={{ margin: "0px" }}
                checked={
                  add
                    ? day.checkedHalfDay
                    : day.diaInteiro === "1"
                    ? true
                    : false
                }
                disabled={add ? false : true}
                onChange={() => handleCheckboxChange(day, "halfDay")}
              />
            }
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <b style={{ cursor: "default" }}>
            {add
              ? moment(day.start).format(
                  `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                    "portalrh.workDays.de"
                  )}] YYYY`
                )
              : moment(day.date).format(
                  `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                    "portalrh.workDays.de"
                  )}] YYYY`
                )}
          </b>

          {add && (
            <div
              style={{
                cursor: "pointer",
                marginLeft: "auto",
                color: "#AD3E3E",
              }}
              onClick={() => removeDayFromEvents(day)}
            >
              <FaTrash />
            </div>
          )}
        </div>
      </ListGroup.Item>
    ));

  const toastResult = (text) => {
    return (
      <Toast>
        <Toast.Body className="bg-danger" style={{ color: "white" }}>
          {text}
        </Toast.Body>
      </Toast>
    );
  };

  const toastResultMSG = (text) => {
    if (text === "error" || text === "errorDelete")
      return (
        <Toast>
          <Toast.Body className="bg-danger" style={{ color: "white" }}>
            {text === "error"
              ? t("portalrh.workDays.error")
              : t("portalrh.workDays.errorDelete")}
          </Toast.Body>
        </Toast>
      );
    if (text === "sucess")
      return (
        <Toast>
          <Toast.Body className="bg-success" style={{ color: "white" }}>
            {t("portalrh.workDays.success")}
          </Toast.Body>
        </Toast>
      );
    if (text === "delete")
      return (
        <Toast>
          <Toast.Body className="bg-success" style={{ color: "white" }}>
            {t("portalrh.workDays.delete")}
          </Toast.Body>
        </Toast>
      );
  };

  const handleExport = (type) => {
    if (type === "criteria") {
      setShowModalCriteria(true);
    } else {
      let list;
      if (type === "screen") {
        const filteredDiasMarcados = trabalhoRemoto.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getMonth() === dateSelected.getMonth()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      if (type === "year") {
        const filteredDiasMarcados = trabalhoRemoto.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getFullYear() === dateSelected.getFullYear()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      const monthName = moment(dateSelected).format("MMMM");
      const yearDate = moment(dateSelected).format("YYYY");
      const payload = {
        type,
        list,
        monthName,
        yearDate,
      };
      sendExportData(payload);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSendCriteriaData = () => {
    if (Object.keys(gruposTrabalhoRemoto).length > 0) {
      let ids;
      if (criteriaGroup === "") {
        const allUsersCriteria = gruposTrabalhoRemoto.flatMap(
          (group) => group.userList
        );
        ids = allUsersCriteria.map((user) => user.id);
      } else {
        const selectedGroupCriteria = gruposTrabalhoRemoto.find(
          (group) => group.grupo.id === criteriaGroup
        );
        ids = selectedGroupCriteria.userList.map((user) => user.id);
      }
      if (startAt === "" || endAt === "") {
        setOpenExportError(true);
        return;
      }
      const payload = {
        type: "criteria",
        dataInicio: formatDate(new Date(startAt)),
        dataFim: formatDate(new Date(endAt)),
        ids,
      };
      sendExportData(payload);
    }
  };

  const sendExportData = (payload) => {
    dispatch(getExportVals(payload));
    handleModalCloseCriteria();
  };

  class CustomToolbar extends React.Component {
    render() {
      let {
        localizer: { messages },
        label,
      } = this.props;
      return (
        <>
          <div className="rbc-toolbar">
            <Row style={{ width: "100%", marginBottom: "20px" }}>
              {isEditable && (
                <Col sm="12" md="12" lg="12" style={{ padding: "0px" }}>
                  <Stack
                    direction="row-reverse"
                    spacing={2}
                    style={{
                      width: "100%",
                      padding: "0.3rem 0rem",
                      textAlignLast: "center",
                    }}
                  >
                    <Col
                      className="text-end"
                      style={{ padding: "0px", textAlignLast: "right" }}
                    >
                      <ButtonBoot
                        className="card-button"
                        onClick={handleSendDays}
                        style={{ color: "white" }}
                      >
                        {t("portalrh.workDays.newMark")}
                      </ButtonBoot>
                      {deleteDays.length > 0 && (
                        <Button
                          variant="contained"
                          color="error"
                          className="card-button"
                          onClick={handleSendDeleteDays}
                          style={{ color: "white" }}
                        >
                          <span
                            style={{ display: "inline-flex", gap: "0.2rem" }}
                          >
                            <FaTrash />
                            {t("portalrh.workDays.deleteWorkDays")}
                          </span>
                        </Button>
                      )}
                    </Col>
                    <Col
                      sm="12"
                      md={exportBtn ? "6" : !hideChangeView ? "6" : "12"}
                      lg={exportBtn ? "6" : !hideChangeView ? "6" : "12"}
                      style={{ padding: "0px", display: "flex" }}
                      className={hideChangeView ? "text-end" : ""}
                    >
                      <Col
                        sm="12"
                        lg={!hideChangeView ? "5" : "4"}
                        md={!hideChangeView ? "5" : "4"}
                        className="text-end"
                        style={{ padding: "0px", textAlignLast: "left" }}
                      >
                        {!hideChangeView && (
                          <>
                            <ToggleButtonGroup
                              value={defaultView}
                              exclusive
                              onChange={(event, value) =>
                                this.changeView(value)
                              }
                              aria-label="calendar view"
                            >
                              <ToggleButton value="month" aria-label="month">
                                <FaCalendarDay /> {t("portalrh.workDays.mes")}
                              </ToggleButton>
                              <ToggleButton value="year" aria-label="year">
                                <FaCalendarAlt /> {t("portalrh.workDays.ano")}
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </>
                        )}
                      </Col>
                      <Col
                        sm="12"
                        lg={!hideChangeView ? "7" : "4"}
                        md={!hideChangeView ? "7" : "4"}
                        className="text-end"
                        style={{ padding: "0px", textAlignLast: "left" }}
                      >
                        <ColorButtonArrow
                          size="small"
                          variant="text"
                          startIcon={<FaAngleDoubleLeft />}
                          onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                        />
                        <span className="rbc-toolbar-label">{label}</span>
                        <ColorButtonArrow
                          size="small"
                          variant="text"
                          startIcon={<FaAngleDoubleRight />}
                          onClick={this.navigate.bind(null, navigate.NEXT)}
                        />
                      </Col>
                    </Col>
                    {showToast && toastResult(toastText)}
                    {showToastMSG && toastResultMSG(toastText)}
                  </Stack>
                </Col>
              )}
            </Row>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
              {!isEditable && exportBtn && (
                <>
                  <Col
                    sm="12"
                    md={exportBtn ? "10" : !hideChangeView ? "7" : "12"}
                    lg={exportBtn ? "10" : !hideChangeView ? "7" : "12"}
                    style={{ padding: "0px" }}
                    className={hideChangeView ? "text-end" : ""}
                  >
                    <ColorButtonArrow
                      size="small"
                      variant="text"
                      startIcon={<FaAngleDoubleLeft />}
                      onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                    />
                    <span className="rbc-toolbar-label">{label}</span>
                    <ColorButtonArrow
                      size="small"
                      variant="text"
                      startIcon={<FaAngleDoubleRight />}
                      onClick={this.navigate.bind(null, navigate.NEXT)}
                    />
                  </Col>
                  <Col
                    sm="12"
                    md={"2"}
                    lg={"2"}
                    style={{ padding: "0px", textAlignLast: "right" }}
                  >
                    <DropDownBoot>
                      <DropDownBoot.Toggle
                        id="dropdown-basic"
                      >
                        {t("portalrh.workDays.export.btn")}
                      </DropDownBoot.Toggle>

                      <DropDownBoot.Menu style={{ textAlignLast: "left" }}>
                        <DropDownBoot.Item
                          onClick={() => handleExport("screen")}
                        >
                          {t("portalrh.workDays.export.item1")}
                        </DropDownBoot.Item>
                        <DropDownBoot.Item onClick={() => handleExport("year")}>
                          {t("portalrh.workDays.export.item2")}
                        </DropDownBoot.Item>
                        <DropDownBoot.Item
                          onClick={() => handleExport("criteria")}
                        >
                          {t("portalrh.workDays.export.item3")}
                        </DropDownBoot.Item>
                      </DropDownBoot.Menu>
                    </DropDownBoot>
                  </Col>
                </>
              )}
            </Row>
            {isEditable &&
              textoContador !== "" &&
              textoContadorAdicional != "" &&
              defaultView !== "year" && (
                <Row style={{ width: "100%", marginBottom: "20px" }}>
                  <Col sm="12" md="12" lg="12" style={{ padding: "0px" }}>
                    <span>
                      {" "}
                      {t("portalrh.workDays.textoNrDiasBolsa.1")}{" "}
                      {textoContador}
                      {t("portalrh.workDays.textoNrDiasBolsa.2")}{" "}
                      {textoContadorAdicional}{" "}
                      {t("portalrh.workDays.textoNrDiasBolsa.3")}
                    </span>
                  </Col>
                </Row>
              )}
          </div>
        </>
      );
    }
    navigate = (action) => {
      this.props.onNavigate(action);
    };
    changeView = (view) => {
      this.props.onView(view);
    };
  }
  const handleModalClose = () => {
    const updatedEventsDays = events.map((eventDay) => ({
      ...eventDay,
      checked: true,
      checkedHalfDay: false,
    }));

    setEvents(updatedEventsDays);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
    dispatch(postTrabalhoRemoto({}));
    setComentario("");
  };

  const handleCloseExport = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setExportVals(""));
    setOpenExport(false);
    setmessageTranslated("");
  };

  const handleCloseExportError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenExportError(false);
  };

  return (
    <>
      <Spinner spinning={isLoadingExport} wrapper />
      <Snackbar
        open={openExport}
        autoHideDuration={5000}
        onClose={handleCloseExport}
      >
        <Alert
          onClose={handleCloseExport}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openExportError}
        autoHideDuration={5000}
        onClose={handleCloseExportError}
      >
        <Alert
          onClose={handleCloseExportError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("portalrh.workDays.export.dateToExport")}
        </Alert>
      </Snackbar>
      {/* <Sidebar menuSideBar={menuSideBar} path={path} /> */}
      {isLoadingTrabalhoRemoto ? (
        <Spinner spinning={isLoadingTrabalhoRemoto} wrapper />
      ) : (
        <>
          <div
            style={{ padding: !hideChangeView ? "" : "10px" }}
            className={
              !exportBtn ? "calendarScrollbarComponent" : "calendarComponent"
            }
          >
            {!isEditable &&
              Object.keys(gruposTrabalhoRemoto).length > 0 &&
              listUsersByGroupConstructor()}
            <>{selectedLocale && schedulerContrutor()}</>
          </div>
        </>
      )}
      <ModalMaterial
        open={showModalWithDaysMarked}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 1rem",
            }}
          >
            <h4 style={{ color: "#4C4B4B" }}>
              {t("portalrh.workDays.listmarkedDays")}
            </h4>
            <button type="button" class="close" onClick={handleModalClose}>
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
          </Stack>
          {eventDaysForModal.length > 0 && (
            <ListGroup
              style={{
                overflowY: "auto",
                maxHeight: "350px",
                padding: "10px",
              }}
              className="scrollBar-visible-year"
            >
              {renderListModals(eventDaysForModal, true)}
            </ListGroup>
          )}
          <Stack
            direction="row-reverse"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "right",
              padding: "0px 16px",
            }}
          >
            <ButtonBoot
              className="card-button-Style"
              onClick={() => handleSaveWorkDays()}
            >
              {t("portalrh.workDays.confirmDays")}
            </ButtonBoot>
            <ButtonBoot
              className="card-button-redStyle"
              onClick={handleModalClose}
            >
              {t("portalrh.workDays.close")}
            </ButtonBoot>
          </Stack>
        </Box>
      </ModalMaterial>
      <ModalMaterial
        open={showModalWithDaysMarkedConfirm}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "space-between",
              padding: "1rem 1rem",
            }}
          >
            <h4 style={{ color: "#4C4B4B" }}>
              {t("portalrh.workDays.listmarkedDays")}
            </h4>
            <button type="button" class="close" onClick={handleModalClose}>
              <span aria-hidden="true">×</span>
              <span class="sr-only">Close</span>
            </button>
          </Stack>

          <Form style={{ padding: "10px" }}>
            {Object.keys(trabalhoRemotomsg).length > 0 &&
              trabalhoRemotomsg.semanal.length > 0 &&
              trabalhoRemotomsg.contador.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasSemanais.1")}{" "}
                    {trabalhoRemotomsg.contador[0].contadorSemanal}{" "}
                    {t("portalrh.workDays.textoDiasSemanais.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(trabalhoRemotomsg.semanal)}
                  </ListGroup>
                </>
              )}

            {Object.keys(trabalhoRemotomsg).length > 0 &&
              trabalhoRemotomsg.exedente.length > 0 &&
              trabalhoRemotomsg.contador.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasExcedentes.1")}{" "}
                    {trabalhoRemotomsg.contador[0].contadorAnual}{" "}
                    {t("portalrh.workDays.textoDiasExcedentes.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(trabalhoRemotomsg.exedente)}
                  </ListGroup>
                </>
              )}

            {Object.keys(trabalhoRemotomsg).length > 0 &&
              trabalhoRemotomsg.bolsa.length > 0 &&
              trabalhoRemotomsg.contador.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoBolsa.1")}{" "}
                    {trabalhoRemotomsg.contador[0].contadorBolsa}{" "}
                    {t("portalrh.workDays.textoBolsa.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "330px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(trabalhoRemotomsg.bolsa)}
                  </ListGroup>
                </>
              )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>{t("portalrh.workDays.comentario")}</Form.Label>
              <Form.Control
                style={{ padding: "10px" }}
                as="textarea"
                rows={3}
                value={comentario}
                onChange={(e) => setComentario(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Stack
            direction="row-reverse"
            spacing={2}
            style={{
              width: "100%",
              justifyContent: "right",
              padding: "0px 16px",
            }}
          >
            <ButtonBoot
              className="card-button-Style"
              onClick={() => handleSaveWorkDaysConfirm()}
            >
              {t("portalrh.workDays.confirmDays")}
            </ButtonBoot>
            <ButtonBoot
              className="card-button-redStyle"
              onClick={handleModalClose}
            >
              {t("portalrh.workDays.close")}
            </ButtonBoot>
          </Stack>
        </Box>
      </ModalMaterial>
      <ModalWithCheckDays
        show={showModalCheckDays}
        onHide={handleModalCloseCheckDays}
      />
      <ModalWithCriteria
        show={showModalCriteria}
        onHide={handleModalCloseCriteria}
      />
    </>
  );
};

export default withNamespaces()(SchedulerComponent);
