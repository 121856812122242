import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
//import { jsonData } from "./forms/jsonData"; //custom button
//import { jsonData } from "./forms/1/jsonData"; //Custo consulta
//import { jsonData } from "./forms/2/jsonData"; //Comparativo consulta
//import { jsonData } from "./forms/3/jsonData"; //Comparativo
//import { jsonData } from "./forms/4/jsonData"; //Prospeçao
//import { jsonData } from "./forms/5/jsonData"; //Rendimentos
//import { jsonData } from "./forms/6/jsonData"; //Prospeçao consulta
//import { jsonData } from "./forms/7/jsonData"; //Custo
//import { jsonData } from "./forms/8/jsonData"; //Rendimentos consulta
//import { jsonData } from "./forms/9/jsonData"; //forwardpage
//import { jsonData } from "./forms/17/jsonData";
import RenderForm from "./components/RenderForm";
import CreateForwardPage from "./components/CreateForwardPage";
import CreateEndPage from "./components/CreateEndPage";
import CreateErrorPage from "./components/CreateErrorPage";
import Spinner from "~/components/Spinner";
import { getHeaderName } from "./utils";
import { useRouteMatch } from "react-router-dom";
import { resetIflowDocuments } from "~/store/ducks/processes/actionTypes";
import {
  resetIflowDocumentsLinkComponent,
  setSelectedTosignLinkComponent,
} from "~/store/ducks/processesSignature/actionTypes";
import { setSignedPdf } from "~/store/ducks/onboarding/actionTypes";
const devLogConsole = require("~/utils/devLog");
const CreateForm = (props) => {
  const {
    jsonData,
    isModal,
    className,
    handleHeaderName = () => {},
    isExternalPage = false,
    handleClose,
    heightModal,
  } = props;
  const { isLoadingFormdata } = useSelector((state) => state.processesReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);

  const { userSettings } = useSelector((state) => state.userSettingsReducer);

  let language = userSettings?.language ? userSettings.language : "pt";

  let { path } = useRouteMatch();

  const [styleSheet, setFormStyleSheet] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [translations, setTranslations] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (jsonData) {
      const translationStylesheet = gettranslations();
      setHeaderName(
        headerTextName(getHeaderName(jsonData), translationStylesheet)
      );
      handleHeaderName(
        headerTextName(getHeaderName(jsonData), translationStylesheet)
      );
    }

    return () => {
      setHeaderName("");
      dispatch(resetIflowDocumentsLinkComponent());
      dispatch(setSelectedTosignLinkComponent([]));
      dispatch(resetIflowDocuments());
      dispatch(setSignedPdf(null));
    };
  }, []);

  const gettranslations = () => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        return extraConfiguration_?.translations;
      }
    }
  };

  const headerTextName = (textHeader, translationStylesheet) => {
    if (!textHeader.includes("::t::")) {
      return textHeader;
    }
    const lingua = language.trim();
    const cleanTextField = textHeader.replace(/'/g, "").trim();
    const splittext = cleanTextField.split("::t::");

    const textoFinal = splittext
      .map((tt) => {
        const splitSplitedText = tt.split("bmp.");
        if (splitSplitedText.length > 1 && translationStylesheet?.[lingua]) {
          return (
            splitSplitedText[0] +
            translationStylesheet[lingua]["bmp." + splitSplitedText[1]]
          );
        } else {
          return tt;
        }
      })
      .join("");

    return textoFinal;
  };

  /*Get extraconfiguration*/
  useEffect(() => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        //devLogConsole(styleSheet_)
        setFormStyleSheet(styleSheet_);
        if (
          extraConfiguration_?.translations === undefined ||
          extraConfiguration_?.translations === null
        ) {
          setTranslations(extraConfiguration_?.styleSheet.translations);
        } else {
          setTranslations(extraConfiguration_?.translations);
        }
      }
    }
  }, [menu]);

  const renderResponse = (styleSheet) => {
    if (jsonData) {
      if (jsonData?.url?.includes("proc_info.jsp")) {
        return (
          <CreateForwardPage
            jsonData={jsonData}
            styleSheet={styleSheet}
            isModal={isModal}
            handleClose={handleClose}
          />
        );
      }
      if (jsonData?.url?.includes("end.jsp")) {
        return (
          <CreateEndPage
            jsonData={jsonData}
            isModal={isModal}
            handleClose={handleClose}
            styleSheet={styleSheet}
          />
        );
      }
      if (
        jsonData?.url?.includes("flow_error.jsp") ||
        jsonData?.url?.includes("error.jsp") ||
        jsonData?.url?.includes("nopriv.jsp") ||
        jsonData?.url?.includes("nop.jsp") ||
        (jsonData?.url?.includes("form.jsp") &&
          jsonData?.responseMessage.includes('<div class="error_msg">'))
      ) {
        return (
          <CreateErrorPage
            jsonData={jsonData}
            isModal={isModal}
            handleClose={handleClose}
            styleSheet={styleSheet}
          />
        );
      }

      if (jsonData.responseMessage) {
        const substrings = ["<div>", "<table>", "p"];
        if (new RegExp(substrings.join("|")).test(jsonData.responseMessage)) {
          // At least one match
          return <div></div>;
          {
            /* <div
              dangerouslySetInnerHTML={{ __html: jsonData.responseMessage }}
          />*/
          }
        } else return <div>{jsonData.responseMessage}</div>;
      } else if (jsonData.form) {
        return (
          <RenderForm
            data={jsonData}
            styleSheet={styleSheet}
            isModal={isModal}
            translations={translations}
          />
        );
      }
    } else return <div></div>;
  };
  
  const combinedStyles = {
    // ...(styleSheet?.minimizeSidebar ? { padding: "2rem 3rem 2rem 0rem" } : {}),
    ...(heightModal ? { height: heightModal } : {}),
  };

  const isMobile = window.innerWidth < 992 ? true : false;
  return !isLoadingFormdata ? (
    <div
      className={className ? `${className} scrollBar-visible` : "main-card-v2"}
      style={combinedStyles}
    >
      <Card
        bsPrefix="card-flat"
        style={
          (jsonData?.url?.includes("flow_error.jsp") ||
            jsonData?.url?.includes("error.jsp") ||
            jsonData?.url?.includes("end.jsp")) &&
          styleSheet?.badgesMessage
            ? { overflowY: "visible", height: "300px" }
            : { overflowY: "visible" }
        }
      >
        {(path.includes("/tasks") || path.includes("/tarefas")) &&
        styleSheet?.hideFormHeader ? (
          <div></div>
        ) : (
          <>
            {headerName !== "" && (
              <Card.Header
                style={{ padding: "15px 40px" }}
                className="justify-content-between"
              >
                {!isLoadingFormdata && (
                  <div className="custom-title">{headerName}</div>
                )}
              </Card.Header>
            )}
          </>
        )}
        <Card.Body
          style={{
            overflowY: isModal ? "none" : "visible",
            padding: !styleSheet.dontShowPrintButton
              ? "20px 30px 20px 20px"
              : isMobile
              ? "20px 0px"
              : "20px",
          }}
        >
          {renderResponse(styleSheet)}
        </Card.Body>
      </Card>
    </div>
  ) : (
    <Spinner spinning={isLoadingFormdata} wrapper />
  );
};

export default CreateForm;
