import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import Dropdown from "~/components/Dropdown";
import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const EventItem = ({
  event,
  updateEventState,
  removeDayFromEvents,
  disabled,
  isToRemove,
  t,
}) => {
  const { consumoSaldo } = useSelector((state) => state.portalrhReducer);

  function EventCheckbox({ label, checked, onChange, disabled }) {
    return (
      <Form.Check
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const stateArray = {
    [t("portalrh.workDays.feriasTranslated.optFirstPart")]: "1",
    [t("portalrh.workDays.feriasTranslated.optSecoundPart")]: "2",
  };
  const consumosArray = {
    [t("portalrh.workDays.feriasTranslated.optLastYear")]: "1",
    [t("portalrh.workDays.feriasTranslated.optCurrentYear")]: "2",
    [t("portalrh.workDays.feriasTranslated.optNextYear")]: "3",
  };
  const renderOptions = () => {
    return Object.entries(stateArray).map(([key, value]) => {
      return {
        label: key,
        value: value,
        selected: false,
      };
    });
  };

  const renderOptionsConsumo = () => {
    return Object.entries(consumosArray).map(([key, value]) => {
      return {
        label: key,
        value: value,
        selected: false,
      };
    });
  };

  const handleChangeOption = (e, id) => {
    updateEventState(id, { parteDia: e });
  };

  const handleChangeOptionConsumo = (e, id) => {
    updateEventState(id, { empConsumoSaldo: e });
  };

  return (
    <ListGroup.Item className="event-item">
      <Row>
        <Col>
          <Row>
            <Col
              className="checkbox-group"
              style={{
                display: isMobile ? "block" : "inline-flex",
                gap: "2rem",
              }}
            >
              {!isToRemove && (
                <>
                  <EventCheckbox
                    label={t("portalrh.workDays.diaInteiro")}
                    checked={event.checked}
                    disabled={disabled}
                    onChange={(e) =>
                      updateEventState(event.id, {
                        checked: e.target.checked,
                        checkedHalfDay:
                          !e.target.checked && event.checkedHalfDay,
                        parteDia: "0",
                      })
                    }
                  />
                  <EventCheckbox
                    label={t("portalrh.workDays.diasMarcadosMeiodia")}
                    checked={event.checkedHalfDay}
                    disabled={disabled}
                    onChange={(e) =>
                      updateEventState(event.id, {
                        checkedHalfDay: e.target.checked,
                        checked: !e.target.checked && event.checked,
                      })
                    }
                  />
                  {event.checkedHalfDay && (
                    <div>
                      <Form.Label>
                        {t("portalrh.workDays.feriasTranslated.titlePartDay")}
                      </Form.Label>
                      <Dropdown
                        list={renderOptions()}
                        handleSubmit={(e) => handleChangeOption(e, event.id)}
                        emptyDefault={true}
                        flowId={event.parteDia}
                        required
                      />
                    </div>
                  )}
                  {Object.keys(consumoSaldo).length > 0 &&
                    consumoSaldo?.empConsumoSaldo === "0" && (
                      <div>
                        <Form.Label>
                          {t("portalrh.workDays.feriasTranslated.titleConsume")}
                        </Form.Label>
                        <Dropdown
                          list={renderOptionsConsumo()}
                          handleSubmit={(e) =>
                            handleChangeOptionConsumo(e, event.id)
                          }
                          emptyDefault={true}
                          flowId={event.empConsumoSaldo}
                          required
                        />
                      </div>
                    )}
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="date-label" style={{ display: "inline-flex" }}>
              <Form.Label>
                <b>{moment(event.start).format(`DD [de] MMMM [de] YYYY`)}</b>
              </Form.Label>
              {event.type !== "datasMarcacoes" && !isToRemove ? (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    color: "#AD3E3E",
                  }}
                  onClick={() => removeDayFromEvents(event)}
                >
                  <FaTrash />
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <EventCheckbox
                    checked={event.daytoRemove}
                    disabled={false}
                    onChange={(e) =>
                      updateEventState(event.id, {
                        daytoRemove: e.target.checked,
                      })
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default withNamespaces()(EventItem);
